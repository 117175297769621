import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  withStyles,
  Button,
  Box
} from '@material-ui/core';
import { TableSortLabel } from '@material-ui/core';

export const TableWrapper = withStyles(() => ({
  root: {
    borderCollapse: 'separate',
    borderSpacing: '0px 10px',
  }
}))(Table);
export const TableRowWrapper = withStyles(() => ({
  root: {
    marginBottom: 10
  }
}))(TableRow);
export const TableCellWrapper = withStyles(theme => ({
  root: ({ center }) => ({
    padding: 10,
    fontFamily: 'Rubik',
    width: center ? 60 : 'auto',
    margin: center ? '0px auto' : '0px',
    [theme.breakpoints.down('sm')]: {
      padding: 3,
      height: 'auto !important'
    },
    ...(center
      ? {
          '& > .MuiButton-root': {
            margin: '0 auto',
            display: 'block'
          }
        }
      : {})
  })
}))(TableCell);
export const TableBodyWrapper = withStyles(() => ({
  root: {
    '& .MuiTableRow-root': {
      // background: 'linear-gradient(to top, rgba(107, 107, 107, 0.1), white)',
      borderRadius: 10,
      boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
      '&:hover': {
        // background: 'linear-gradient(to top, rgba(40, 151, 251, 0.2), white)'
      },
      '& .MuiTableCell-root': {
        color: '#6b6b6b',
        fontSize: 13,
        height: 60,
        '&:not(:last-child)': {
          borderRight: 'solid 1px rgba(0, 0, 0, 0.2)'
        },
        '&:first-child': {
          borderRadius: '10px 0px 0px 10px'
        },
        '&:last-child': {
          borderRadius: '0px 10px 10px 0px'
        }
      }
    }
  }
}))(TableBody);
export const TableHeadWrapper = withStyles(() => ({
  root: {
    '& .MuiTableRow-root': {
      background: 'linear-gradient(to top, #04274f, #2897fb)',
      borderRadius: 10,
      boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
      '& .MuiTableCell-root': {
        color: '#d5eaff',
        textTransform: 'uppercase',
        fontSize: 11,
        height: 40,
        fontWeight: 100,
        '&:not(:last-child)': {
          borderRight: 'solid 1px rgba(255, 255, 255, 0.2)'
        },
        '&:first-child': {
          borderRadius: '10px 0px 0px 10px'
        },
        '&:last-child': {
          borderRadius: '0px 10px 10px 0px'
        }
      }
    }
  }
}))(TableHead);

export const TableSortLabelWrapper = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover, &:hover .MuiTableSortLabel-icon': {
      color: '#fff'
    },
    '&.MuiTableSortLabel-active, &.MuiTableSortLabel-active .MuiTableSortLabel-icon':
      {
        color: '#fff !important'
      }
  }
}))(TableSortLabel);

export const TableSelectWrapper = withStyles(() => ({
  root: {
    width: 18,
    height: 18,
    padding: 0,
    borderRadius: '50%',
    minWidth: 18,
    border: '1px solid #626262',
    background: ({ selected }) =>
      selected
        ? 'linear-gradient(to top, rgb(21 21 21), rgb(129 129 129))'
        : 'none'
  }
}))(Button);

export const EmptyTableWrapper = withStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    // background: 'linear-gradient(to top, rgba(107, 107, 107, 0.1), white)',
    borderRadius: 10,
    boxShadow: '0 5px 10px rgb(0 0 0 / 20%)',
    padding: 15,
    minHeight: 300,
    '& svg': {
      width: 50,
      height: 50,
      marginBottom: 10,
      color: '#2897fb'
    },
    '& h1': {
      color: '#323232'
    }
  }
}))(Box);
