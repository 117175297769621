import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import {AdminAPI, CandidateAPI, CompanyAPI} from "../../../api/v2";
import Input from "../../../components/input";
import Select from "../../../components/select";
import Button from "../../../components/button/index";

const CreateUserModal = ({ onClose, state, setState, user }) => {
  const [values, setValues] = useState({
    full_name: user?.full_name || '',
    email: user?.email || '',
    role: user?.role || '',
    password: '',
    password_confirmation: ''
  })


  const submit = async () => {
    if (Object.keys(values).filter(x => values[x].trim() === '').length - (user ? 2 : 0)) {
      return toast.warning('Sva polja moraju biti popunjena');
    }

    if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(values.email.trim())) {
      return toast.warning('Neispravan email');
    }

    if (values.password !== values.password_confirmation) {
      return toast.warning('Lozinke se ne podudaraju');
    }

    try {
      if (user) {
        const {full_name, email} = values;
        if (user.role === 'employee') await CandidateAPI.update({full_name, email}, user.id)
        if (user.role === 'company') await CompanyAPI.update({full_name, email}, user.id)
      } else {
        await AdminAPI.userCreate(values)
      }
      onClose();
      setState({ ...state });
      return toast.success('Korisnik je kreiran');
    } catch (e) {
      return toast.error('Greska');
    }

  }

  return (
    <Fragment>
      <Input
        className="force-space-b"
        label="Naziv korisnika"
        value={values.full_name}
        onChange={full_name => setValues({ ...values, full_name })}
      />
      <Input
        className="force-space-b"
        label="Email adresa"
        value={values.email}
        onChange={email => setValues({ ...values, email })}
      />
      <Select
        className="force-space-b"
        label="Uloga korisnika"
        value={values.role}
        disabled={user}
        onChange={role => setValues({ ...values, role })}
        options={[
          { value: 'admin', label: 'Admin' },
          { value: 'company', label: 'Kompanija' },
          { value: 'employee', label: 'Kandidat' }
        ]}
      />
      {!user && <Input
        className="force-space-b"
        label="Lozinka"
        value={values.password}
        onChange={password => setValues({ ...values, password })}
        type="password"
      />}
      {!user && <Input
        className="force-space-b"
        label="Ponovite lozinku"
        value={values.password_confirmation}
        onChange={password_confirmation =>
          setValues({ ...values, password_confirmation })
        }
        type="password"
      />}
      <Button onClick={submit}>Sačuvajte</Button>
    </Fragment>
  );
};

export default React.memo(CreateUserModal);
