/**
 * API base URL
 * @type {string}
 */
export const apiBaseUrl = `https://admin.cvprica.me`;

/**
 * user token
 * @type {string}
 */
export const token = localStorage.getItem('Authorization');

export const imagePlaceholder = '/images/mockup/profile-img-placeholder.png';
export const videoPlaceholder = '/images/mockup/video-placeholder.png';
