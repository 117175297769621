import React, { useState } from "react";
import './style.scss';

const Image = React.forwardRef((props, ref) => {
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  return (
    <img
      alt={props.alt}
      {...props}
      className={`cv-image-load ${
        state.loaded ? "cv-image-load-success" : ""
      } ${props.className}`}
      ref={ref}
      onLoad={(e) => setState({ ...state, loaded: true })}
      onError={(e) => setState({ ...state, error: true })}
    />
  );
});

export default Image;
