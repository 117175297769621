import { IconButton } from '@material-ui/core';
import {Close, CloudUpload, EditOutlined, Save} from '@material-ui/icons';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import {connect, useSelector} from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { CandidateAPI, GlobalAPI } from '../../../api/v2';
import Input from '../../../components/ui/input/index';
import Select from '../../../components/select';
import { toast } from 'react-toastify';
import { SimpleCard } from "../../../components/ui";
import { genders } from "../../../constants/selects";
import PhoneInput from "../../../components/custom/phoneinput";
import DataDisplay from "../../../components/custom/data-display";
import Progress from "../../../components/custom/progress";
import "./styles.scss"
import DatePicker from "../../../components/datepicker/index";
import Button from "../../../components/button/index";
import { getAllAddings } from "../../../redux/actions/userActions";
import EducationsModal from "./educations-modal";
import WorkExpModal from "./work-experience-modal";
import LanguagesModal from "./languages-modal";
import ComputerSkillsModal from "./computer-skills-modal";
import OtherModal from "./other-modal";
import DrivingLicenceModal from "./driving-licence-modal";
// import UploadImage from "../../../components/custom/uploadimage";

const EditCandidate = ({ addings, getAllAddings }) => {
  const [values, setValues] = useState({
    userId: null,
    full_name: '',
    education_level_id: '',
    email: '',
    gender_id: '',
    country_id: '',
    city_id: '',
    address: '',
    phone: '',
    birth_year: '',
    computer_skills: [],
    languages: [],
    work_experiences: [],
    documents: [],
    zip_code: '',
    educations: null,
    cv_video: '',
    driver_licences: [],
    aditional_info: '',
    profile_image: '',
    is_email_verified: false,
    is_phone_verified: false
  });

  const { id } = useParams();

  const { common } = useSelector(state => state.global);
  const [educationLevels, setEducationLevels] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  // const [files, setFiles] = useState({
  //   profile_image: ''
  // })

  const { user } = useSelector(state =>state.global);

  const [loaded, setLoaded] = useState(false);

  const history = useHistory();

  const getCandidate = async () => {
    const {
      full_name,
      education_level,
      email,
      gender,
      country,
      city,
      address,
      phone,
      computer_skills,
      languages,
      work_experiences,
      documents,
      profile_image,
      birth_year,
      zip_code,
      educations,
      cv_video,
      driver_licences,
      aditional_info
    } = await CandidateAPI.get(id);

    const formattedEducationLevels = common.education_levels.map(x => ({
      value: x.id,
      label: x.name
    }));
    setEducationLevels(formattedEducationLevels);
    await getCountries();
    if (country?.id) await getCities(country.id);
    setValues({
      userId: id,
      full_name,
      education_level_id: education_level?.id,
      email,
      birth_year,
      gender_id: gender?.id,
      country_id: country?.id,
      city_id: city?.id,
      address,
      phone,
      computer_skills,
      languages,
      work_experiences,
      documents,
      zip_code,
      educations,
      profile_image,
      cv_video,
      driver_licences,
      aditional_info,
    });
    setLoaded(true);
  };

  const getCountries = async () => {
    const res = await GlobalAPI.countries();
    const formattedCountries = res.map(x => ({ value: x.id, label: x.name }));
    setCountries(formattedCountries);
  };

  const getCities = async country_id => {
    if (country_id) {
      setValues({ ...values, country_id });
      const res = await GlobalAPI.cities(country_id);
      const formattedCities = res.map(x => ({ value: x.id, label: x.name }));
      setCities(formattedCities);
    } else {
      setCities([]);
      setValues({ ...values, country_id: '', city_id: '' });
    }
  };
  const [educationOpen, setEducationOpen] = useState(false);
  const [workExpOpen, setWorkExpOpen] = useState(false);
  const [languagesOpen, setLanguagesOpen] = useState(false);
  const [computerOpen, setComputerOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [drivingOpen, setDrivingOpen] = useState(false);
  useEffect(() => {
    getCandidate();
    getAllAddings();
  }, []);

  const getPercent = () => {
    const len = [
      values.full_name,
      values.gender_id,
      values.birth_year,
      values.education_level_id,
      values.email,
      values.country_id,
      values.city_id,
      values.address,
      values.phone,
      values.zip_code,
      values.educations,
      values.profile_image,
    ];

    const fLen = len.filter((x) =>
      Array.isArray(x) ? x.length > 0 : ![null, undefined, ""].includes(x)
    ).length;
    return Math.round((fLen / len.length) * 80 + (values.cv_video ? 20 : 0));
  };

  const uploadRef = useRef();
  const uploadCvVideo = async (e) => {
    try {
      const user_id = values?.userId;
      const file = e.target.files[0];
      const res = await CandidateAPI.updateProfileVideo(file, user_id);
      setValues({
        ...values,
        cv_video: res?.data?.data?.cv_video
      })
      console.log('res', res);
      toast.success('Video je uspešno uploadovan');
    } catch(e) {
      toast.error("Došlo je do greške")
    }
  }

  const saveChanges = async () => {
    if (values.email.trim() === "") {
      return toast.error(`Email polje je obavezno`);
    }
    try {
      const request_validated = await CandidateAPI.update({
        ...values,
        phone: values.phone.charAt(0) === "+" ? values.phone : `+${values.phone}`,
      }, values?.userId);
      setValues({
        ...user,
        ...request_validated?.data?.data,
      });
      history.goBack();
      toast.success(`Izmjene su uspešno sačuvane`);
    } catch (e) {
      if (e?.response?.data?.message?.includes("Phone must be unique")) {
        toast.error(`Broj telefona već postoji`);
      } else {
        toast.error(`Došlo je do greške`);
      }
    }
  };

  // const uploadProfileImage = async (files) => {
  //   try {
  //     console.log('FILES', files[0])
  //     console.log('USER ID', values?.userId)
  //     const res = await CandidateAPI.updateProfileImage(files[0], values?.userId);
  //     console.log('RES', res)
  //     setValues({ ...values, profile_image: res?.data?.data?.profile_image });
  //     toast.success(`Profilna slika je uspešno promenjena`);
  //   } catch {
  //     toast.error(`Došlo je do greške`);
  //   }
  // };
  // const deleteProfileImage = async (files) => {
  //   try {
  //     const { profile_image } = await CandidateAPI.updateProfileImage(null, values?.userId);
  //     setValues({ ...values, profile_image });
  //     toast.success(`Profilna slika je uspešno promenjena`);
  //   } catch {
  //     toast.error(`Došlo je do greške`);
  //   }
  // };

  return (
    <Fragment>
      <main id="mainContent" className="cv-dashboard-page-scrollable">
        <div>
          {loaded ? (
            <Fragment>
              <div className="cv-dashboard-profile-main cv-dashboard-order-content">
                <SimpleCard
                  className="cv-dashboard-profile-card"
                  title={"Kontakt informacije"}
                  withContent
                >
                  <div className="cv-dashboard-profile-grid">
                    <Input
                      label={"Ime i prezime"}
                      value={values.full_name}
                      className="cv-dashboard-profile-col-double"
                      onChange={(full_name) => setValues({ ...values, full_name })}
                      color="secondary"
                      variant="outlined"
                      // error={errors.full_name}
                      // onBlur={() => onBlur("full_name")}
                      required
                    />
                    <Select
                      label={"Pol"}
                      value={values.gender_id}
                      onChange={(gender_id) => setValues({ ...values, gender_id })}
                      color="secondary"
                      variant="outlined"
                      options={genders}
                      // error={errors.gender_id}
                      // onBlur={() => onBlur("gender_id")}
                      required
                    />
                    <DatePicker
                      label={"Datum rođenja"}
                      value={values.birth_year}
                      onChange={(birth_year) => setValues({ ...values, birth_year })}
                      color="secondary"
                      variant="outlined"
                      // error={errors.birth_year}
                      // onBlur={() => onBlur("birth_year")}
                      required
                    />
                    <Select
                      label={"Nivo obrazovanja"}
                      value={values.education_level_id}
                      onChange={(education_level_id) =>
                        setValues({ ...values, education_level_id })
                      }
                      color="secondary"
                      variant="outlined"
                      options={educationLevels}
                      // error={errors.education_level_id}
                      // onBlur={() => onBlur("education_level_id")}
                      required
                    />
                    <Input
                      label={"E-mail"}
                      value={values.email}
                      onChange={(email) => setValues({ ...values, email })}
                      color="secondary"
                      variant="outlined"
                      error={values.email.trim() === ""}
                      required
                    />
                    <Select
                      label={"Država"}
                      value={values.country_id}
                      onChange={(country_id) => {
                        setValues({ ...values, country_id })
                        getCities(country_id)
                      }}
                      color="secondary"
                      variant="outlined"
                      options={countries}
                      // error={errors.country_id}
                      // onBlur={() => onBlur("country_id")}
                      required
                    />
                    <Select
                      label={"Grad"}
                      value={values.city_id}
                      onChange={(city_id) => setValues({ ...values, city_id })}
                      color="secondary"
                      variant="outlined"
                      options={cities}
                      // error={errors.city_id}
                      // onBlur={() => onBlur("city_id")}
                      required
                    />
                    <Input
                      className="cv-dashboard-profile-col-double"
                      label={"Ulica i broj prebivališta"}
                      value={values.address}
                      onChange={(address) => setValues({ ...values, address })}
                      color="secondary"
                      variant="outlined"
                      // error={errors.address}
                      // onBlur={() => onBlur("address")}
                      required
                    />
                    <PhoneInput
                      label={"Broj telefona"}
                      value={values.phone}
                      onChange={(phone) => setValues({ ...values, phone })}
                      color="secondary"
                      variant="outlined"
                      placeholder="00-000-0000"
                      required
                    />
                    <Input
                      label={"Poštanski broj"}
                      value={values.zip_code}
                      onChange={(zip_code) => setValues({ ...values, zip_code })}
                      color="secondary"
                      variant="outlined"
                      // error={errors.zip_code}
                      // onBlur={() => onBlur("zip_code")}
                      required
                    />

                    <DataDisplay
                      className="cv-dashboard-profile-col-double"
                      label={"Popunjenost profila"}
                      value={
                        <Progress
                          value={getPercent()}
                          className="cv-dashboard-dashboard-progress"
                          circleClassName="cv-dashboard-dashboard-progress-circle"
                          thickness={4}
                        />
                      }
                    />
                  </div>
                </SimpleCard>
                <SimpleCard
                  className="cv-dashboard-profile-card"
                  title={"Biografija"}
                  withContent
                >
                  {/*<UploadImage*/}
                  {/*  src={values.profile_image}*/}
                  {/*  name={values.full_name}*/}
                  {/*  className="cv-dashboard-profile-upload"*/}
                  {/*  onUpload={uploadProfileImage}*/}
                  {/*  // onDelete={deleteProfileImage}*/}
                  {/*/>*/}
                  {/*<input*/}
                  {/*  type="file"*/}
                  {/*  hidden*/}
                  {/*  // onChange={handleUpload}*/}
                  {/*  ref={uploadRef}*/}
                  {/*  accept="text/plain, application/pdf,  application/docx, application/doc"*/}
                  {/*/>*/}
                  {/*<input*/}
                  {/*  type="file"*/}
                  {/*  hidden*/}
                  {/*  onChange={uploadCvVideo}*/}
                  {/*  ref={uploadRef}*/}
                  {/*  accept="video/mp4,video/x-m4v,video/*"*/}
                  {/*/>*/}
                  <div className="add-ad-row-info">
                      <span>
                        CV Video biografija
                        <div
                          className="upload-video-label"
                          onClick={() => uploadRef.current.click()}
                        >
                          <IconButton className="add-ad-row-actions-icon">
                            <input
                              type="file"
                              hidden
                              ref={uploadRef}
                              onChange={uploadCvVideo}
                            />
                            <CloudUpload />
                          </IconButton>
                          Upload video
                        </div>
                      </span>
                    <div className="candidate-video">
                      {values.cv_video ? (
                        <video src={values.cv_video.video} controls></video>
                      ) : (
                        <img src="/images/mockup/video-placeholder.png" />
                      )}
                    </div>
                  </div>
                  <div className="cv-dashboard-profile-grid">
                    <Input
                      label={"Obrazovanje"}
                      value={values.educations?.map((x) => x.title).join(", ")}
                      className="cv-dashboard-profile-col-double"
                      color="secondary"
                      variant="outlined"
                      disabled
                      endAdornment={
                        <IconButton onClick={() => setEducationOpen(true)}>
                          <EditOutlined />
                        </IconButton>
                      }
                      // error={errors.educations}
                      required
                    />
                    <Input
                      label={"Radno iskustvo"}
                      value={
                        values.work_experiences.length
                          ? values.work_experiences
                            ?.map((x) => x.company_name)
                            .join(", ")
                          : "Bez radnog iskustva"
                      }
                      color="secondary"
                      variant="outlined"
                      disabled
                      endAdornment={
                        <IconButton onClick={() => setWorkExpOpen(true)}>
                          <EditOutlined />
                        </IconButton>
                      }
                    />
                    <Input
                      label={"Strani jezici"}
                      value={values.languages?.map((x) => x.languages_name).join(", ")}
                      color="secondary"
                      variant="outlined"
                      disabled
                      endAdornment={
                        <IconButton onClick={() => setLanguagesOpen(true)}>
                          <EditOutlined />
                        </IconButton>
                      }
                    />
                    <Input
                      className="cv-dashboard-profile-col-double"
                      label={"Rad na računaru"}
                      value={values.computer_skills
                        ?.map((x) => x?.computer_skill_name?.name)
                        .join(", ")}
                      color="secondary"
                      variant="outlined"
                      disabled
                      endAdornment={
                        <IconButton onClick={() => setComputerOpen(true)}>
                          <EditOutlined />
                        </IconButton>
                      }
                    />
                    <Input
                      label={"Ukratko o meni"}
                      value={values.aditional_info}
                      color="secondary"
                      variant="outlined"
                      disabled
                      endAdornment={
                        <IconButton onClick={() => setAboutOpen(true)}>
                          <EditOutlined />
                        </IconButton>
                      }
                    />
                    <Input
                      label={"Vozačka dozvola"}
                      value={values.driver_licences
                        ?.map((x) => x.drivers_licence_category.name)
                        .join(", ")}
                      color="secondary"
                      variant="outlined"
                      disabled
                      endAdornment={
                        <IconButton onClick={() => setDrivingOpen(true)}>
                          <EditOutlined />
                        </IconButton>
                      }
                    />
                  </div>
                </SimpleCard>
              </div>
              <div className="cv-dashboard-bottom-actions">
                <div className="cv-dashboard-button-container">
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Close />}
                    size="large"
                    onClick={() => history.goBack()}
                  >
                    {"OTKAŽITE"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Save />}
                    size="large"
                    onClick={saveChanges}
                  >
                    {"SAČUVAJTE"}
                  </Button>
                </div>
              </div>
              <EducationsModal
                edu={values.educations}
                open={educationOpen}
                onClose={() => setEducationOpen(false)}
                state={values}
                setState={setValues}
                userId={values.userId}
                addings={addings}
              />
              <WorkExpModal
                workexps={values.work_experiences}
                open={workExpOpen}
                onClose={() => setWorkExpOpen(false)}
                state={values}
                setState={setValues}
                userId={values.userId}
                addings={addings}
              />
              <LanguagesModal
                langs={values.languages}
                open={languagesOpen}
                onClose={() => setLanguagesOpen(false)}
                state={values}
                setState={setValues}
                userId={values.userId}
                addings={addings}
              />
              <ComputerSkillsModal
                skills={values.computer_skills}
                open={computerOpen}
                onClose={() => setComputerOpen(false)}
                state={values}
                setState={setValues}
                userId={values.userId}
                addings={addings}
              />
              <OtherModal
                desc={values.aditional_info}
                open={aboutOpen}
                onClose={() => setAboutOpen(false)}
                state={values}
                setState={setValues}
                userId={values.userId}
              />
              <DrivingLicenceModal
                licence={values.driver_licences}
                open={drivingOpen}
                onClose={() => setDrivingOpen(false)}
                state={values}
                setState={setValues}
                userId={values.userId}
                addings={addings}
              />
            </Fragment>
          ) : (
            <div className="edit-ad-loading">
              <h1>Kandidat se učitava</h1>
              <p>Molimo sačekajte</p>
            </div>
          )}
        </div>
      </main>
    </Fragment>
  );
};





function mapStateToProps(state) {
  return {
    addings: state.global.common
  };
}

const mapDispatchToProps = {
  getAllAddings: getAllAddings,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCandidate);
