import { styled } from "@mui/system";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const sizes = {
  small: 400,
  medium: 700,
  large: 1100,
};

export const DialogWrapper = styled(Dialog)(({ theme, size = "small", warning }) => ({
  "& .MuiDialog-paper": {
    background: warning
      ? "linear-gradient(to top, #ffdbad, #ffffff)"
      : "linear-gradient(to top, #f1f4f7, white)",
    width: "100%",
    maxWidth: sizes[size],
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      margin: 10,
      maxHeight: 'calc(100% - 20px)'
    },
    [theme.breakpoints.down("sm")]: {
      margin: 10
    }
  },
}));

export const DialogActionsWrapper = styled(DialogActions)(({ theme }) => ({
  padding: 20,
  "& > *:not(:last-child)": {
    marginRight: 10,
  },
  [theme.breakpoints.down("sm")]: {
    flexWrap: 'wrap',
    padding: 10,
    "& > *": {
      width: "100%",
      marginLeft: "0px !important",
      "&:not(:last-child)": {
      marginRight: 0,
      marginBottom: 10
      }
    },
  },
}));

export const DialogTitleWrapper = styled(DialogTitle)(({ warning, theme }) => ({
  fontWeight: 500,
  textTransform: "uppercase",

  [theme.breakpoints.down("sm")]: {
    padding: `15px 10px`,
  },
}));


export const DialogContentWrapper = styled(DialogContent)(({ warning, theme }) => ({

  [theme.breakpoints.down("sm")]: {
    padding: `15px 10px`,
  },
}));
