import { useState, useEffect, Fragment } from 'react';
import { CandidateAPI } from "../../../../api/v2";
import Modal from "../../../../components/custom/modal";
import Button from "../../../../components/button/index";
import Input from "../../../../components/ui/input";

const OtherModal = ({ desc, open, onClose, userId, ...parent }) => {
  const [state, setState] = useState(desc);

  useEffect(() => {
    setState(desc);
  }, [desc]);

  const saveOther = async () => {
    const res = await CandidateAPI.updateOptionalFields({
      aditional_info: state,
    }, userId);
    parent.setState({ ...parent.state, aditional_info: res?.data?.data?.aditional_info || '' });
    onClose();
  };

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={"OSTALO"}
        size="medium"
        actions={[
          <Button
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            onClick={saveOther}
            key={0}
          >
            {"SAČUVAJ"}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-profile-edit-modal-main">
          <Input
            label={"Ukratko o meni"}
            value={state}
            onChange={(description) => setState(description)}
            color="secondary"
            variant="outlined"
            multiline
            maxRows={4}
            minRows={4}
            required
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default OtherModal;
