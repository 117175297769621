import React from 'react';
import {
  CircularProgressMainWrapper,
  CircularProgressWrapper,
  CircularProgressLabel,
} from "./styles";

const Progress = ({value = 0, className, circleClassName, ...props}) => {
    return (
      <CircularProgressMainWrapper className={className}>
        <CircularProgressWrapper
          variant="determinate"
          value={value}
          className={circleClassName}
          {...props}
        />
        <CircularProgressLabel>{`${Math.round(value)}%`}</CircularProgressLabel>
      </CircularProgressMainWrapper>
    );
}

export default Progress;
