import React, { Fragment } from 'react';
import {
  DialogWrapper,
  DialogActionsWrapper,
  DialogTitleWrapper,
  DialogContentWrapper,
} from "./styles";
import './style.scss';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const Modal = ({ size, children, actions, title, onClose, warning, custom, hideClose, ...props }) => {
  return (
    <DialogWrapper {...props} size={size} onClose={onClose} warning={warning}>
      {!custom ? <Fragment>
        <DialogTitleWrapper warning={warning} id="alert-dialog-title">
          <span>{title}</span>
          {!hideClose ? 
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton> : null}
        </DialogTitleWrapper>
        {children ? (
          <DialogContentWrapper dividers>{children}</DialogContentWrapper>
        ) : null}
        {actions ? (
          <DialogActionsWrapper>{actions}</DialogActionsWrapper>
        ) : null}
      </Fragment> : children}
    </DialogWrapper>
  );
};

export default Modal;
