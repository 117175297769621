import { useState, useEffect, Fragment } from 'react';
import { CandidateAPI } from "../../../../api/v2";
import Modal from "../../../../components/custom/modal";
import Button from "../../../../components/button/index";
import DataDisplay from "../../../../components/custom/data-display";
import { IconButton } from "@material-ui/core";
import Select from "../../../../components/select";
import { Delete } from "@mui/icons-material";

const LanguagesModal = ({ langs, open, onClose, addings, userId, ...parent }) => {
  const [languages, setLanguages] = useState(langs);
  const [state, setState] = useState({
    id: 0,
    language_reads_id: "",
    language_speaks_id: "",
    language_writes_id: "",
    languages_id: "",
  });

  useEffect(() => {
    setLanguages(langs);
  }, [langs]);

  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [reads, setReads] = useState([]);
  const [writes, setWrites] = useState([]);
  const [speaks, setSpeaks] = useState([]);

  const clearFields = () => {
    setState({
      id: 0,
      language_reads_id: "",
      language_speaks_id: "",
      language_writes_id: "",
      languages_id: "",
    });
  };

  useEffect(() => {
    setReads(
      addings?.languages_read.map((x) => ({ value: x.id, label: x.name }))
    );
    setWrites(
      addings?.languages_write.map((x) => ({ value: x.id, label: x.name }))
    );
    setSpeaks(
      addings?.languages_speak.map((x) => ({ value: x.id, label: x.name }))
    );
    setLanguagesOptions(
      addings?.languages.map((x) => ({ value: x.id, label: x.name }))
    );
  }, []);

  const [deleteOpen, setDeleteOpen] = useState(null);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setDeleteOpen(id);
  };

  const loadLang = (x) => {
    setState(x);
  };

  const addLang = async () => {
    try {
      const { end_date, id, ...other } = state;
      if (id === 0) {
        const res = await CandidateAPI.languageStore({
          ...other,
          ...(!other.ongoing ? { end_date } : {}),
        }, userId);
        parent.setState({
          ...parent.state,
          languages: [
            ...parent.state.languages,
            { languages_name: res?.data?.data?.languages?.name, ...res?.data?.data },
          ],
        });
        clearFields();
      } else {
        const res = await CandidateAPI.languageUpdate(id, {
          ...other,
          ...(!other.ongoing ? { end_date } : {}),
        }, userId);
        parent.setState({
          ...parent.state,
          languages: parent.state.languages.map((x) =>
            x.id !== id ? x : { languages_name: res?.data?.data?.languages?.name, ...res?.data?.data }
          ),
        });
        clearFields();
      }
    } catch (e) {}
  };

  const deleteLang = async () => {
    await CandidateAPI.languageDelete(deleteOpen, userId);
    parent.setState({
      ...parent.state,
      languages: parent.state.languages.filter((x) => x.id !== deleteOpen),
    });
    if (deleteOpen === state.id) {
      clearFields();
    }
    setDeleteOpen(null);
  };

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={"STRANI JEZICI"}
        size="large"
        actions={[
          <Button
            grayscale={'true'}
            variant="contained"
            size="large"
            fullWidth
            key={0}
            onClick={clearFields}
          >
            {"OČISTI POLJA"}
          </Button>,
          <Button
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            key={1}
            onClick={addLang}
          >
            {state.id === 0 ? "DODAJ NOVI JEZIK" : "SAČUVAJ"}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-profile-edit-modal-main">
          <div className="cv-dashboard-profile-edit-modal-side">
            {languages?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className={`cv-dashboard-profile-edit-single ${
                  x.id === state.id ? "cv-dashboard-profile-edit-active" : ""
                }`}
                label={`${"Jezik"} ${y + 1}`}
                value={x.languages_name}
                onClick={() => loadLang(x)}
                action={
                  <IconButton onClick={(e) => handleDelete(e, x.id)}>
                    <Delete />
                  </IconButton>
                }
              />
            ))}
          </div>
          <div className="cv-dashboard-profile-edit-modal-side">
            <div className="cv-dashboard-profile-edit-modal-grid-lang">
              <Select
                className="cv-dashboard-profile-col-triple"
                label={"Jezik"}
                value={state.languages_id}
                onChange={(languages_id) =>
                  setState({ ...state, languages_id })
                }
                color="secondary"
                variant="outlined"
                options={languagesOptions}
                required
              />
              <Select
                label={"Čitanje"}
                value={state.language_reads_id}
                onChange={(language_reads_id) =>
                  setState({ ...state, language_reads_id })
                }
                color="secondary"
                variant="outlined"
                options={reads}
                required
              />
              <Select
                label={"Pisanje"}
                value={state.language_writes_id}
                onChange={(language_writes_id) =>
                  setState({ ...state, language_writes_id })
                }
                color="secondary"
                variant="outlined"
                options={writes}
                required
              />
              <Select
                label={"Govor"}
                value={state.language_speaks_id}
                onChange={(language_speaks_id) =>
                  setState({ ...state, language_speaks_id })
                }
                color="secondary"
                variant="outlined"
                options={speaks}
                required
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={typeof deleteOpen === 'number'}
        onClose={() => setDeleteOpen(null)}
        title={"OBRIŠI"}
        size="medium"
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={deleteLang}
            key={2}
          >
            {"OBRIŠI"}
          </Button>,
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setDeleteOpen(null)}
            key={3}
          >
            {"OTKAŽI"}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-cvvideo-modal-content">
          {"Da li ste sigurni da želite da obrišete strani jezik?"}
        </div>
      </Modal>
    </Fragment>
  );
};

export default LanguagesModal;
