import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import {
  DropdownIcon2,
  FiltersIcon,
  FolderIcon,
  PenIcon,
  PlusIcon,
  SearchIcon,
  ProfileIcon,
  MegaphoneIcon
} from '../../components/Icons';
import { CustomInput2 } from '../../components/Inputs';
import Pagination from '../../components/pagination/index';
import NewTable from '../../components/table/index';
import { Title } from '../../components/Title';
import { AdminAPI, CompanyAPI, GlobalAPI } from '../../api/v2';
import moment from 'moment';
import Switch from '../../components/switch';
import Modal from '../../components/modal';
import { IconButton } from '@material-ui/core';
import {
  Delete,
  EditOutlined,
  Email,
  Folder,
  Replay
} from '@material-ui/icons';
import { Fragment } from 'react';
import Select from '../../components/select';
import Button from '../../components/button/index';
import { useRef } from 'react';
import Input from '../../components/input';
import { toast } from 'react-toastify';

var searchTimeout;

const ArchivedCandidates = () => {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [selection, setSelection] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    role: 'employee',
    limit: 5,
    offset: 0,
    term: '',
    is_archived: 1
  });
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    order: 'asc',
    orderBy: 'id'
  });

  // MODALS
  const [filterModal, setFilterModal] = useState({
    open: false
  });
  const onFilterModalClose = () =>
    setFilterModal({ ...filterModal, open: false });

  // MODALS
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    id: 0
  });
  const onDeleteModalClose = () =>
    setDeleteModal({ ...deleteModal, open: false });

  // MODALS
  const [bulkDeleteModal, setBulkDeleteModal] = useState({
    open: false
  });
  const onBulkDeleteModalClose = () =>
    setBulkDeleteModal({ ...bulkDeleteModal, open: false });

  // MODALS
  const [resetModal, setResetModal] = useState({
    open: false,
    id: 0
  });
  const onResetModalClose = () => setResetModal({ ...resetModal, open: false });

  // MODALS
  const [bulkResetModal, setBulkResetModal] = useState({
    open: false
  });
  const onBulkResetModalClose = () =>
    setBulkResetModal({ ...bulkResetModal, open: false });

  const searchRef = useRef();
  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  let history = useHistory();

  const handleSearch = e => {
    setSearch(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setState({ ...state, term: searchRef.current });
    }, 300);
  };

  const onRequestSort = (e, key) => {
    setSort(
      sort.orderBy === key
        ? { ...sort, order: sort.order === 'asc' ? 'desc' : 'asc' }
        : { ...sort, orderBy: key, order: 'asc' }
    );
  };

  const reset = async () => {
    await AdminAPI.userReset(resetModal.id);
    setState({ ...state });
  };

  const deleteUser = async () => {
    await AdminAPI.userDelete(deleteModal.id);
    setState({ ...state });
  };

  const bulkReset = async () => {
    await AdminAPI.usersReset(selection.map(x => x.id));
    setState({ ...state, offset: 0 });
    setSelection([]);
  };

  const deleteUsers = async () => {
    await AdminAPI.usersDelete(selection.map(x => x.id));
    setState({ ...state, offset: 0 });
    setSelection([]);
  };

  // const archive = async () => {
  //   await AdminAPI.userSetArchived(archiveModal.archiveId, true);
  //   setState({ ...state });
  // };

  const getActiveCompanies = async params => {
    try {
      setIsLoading(true);
      const { data, meta } = await AdminAPI.users(params);

      const mappedUsers = data.map(
        ({ updated_at, full_name, city, country, role, ...x }) => ({
          ...x,
          full_name: <Link to={`/candidate/${x.id}/preview`}>{full_name}</Link>,
          location: [city?.name, country?.name_short]
            .filter(x => ![undefined, null, ''].includes(x))
            .join(', '),
          updated_at: moment(updated_at).format('DD-MM-YYYY'),
          role: { admin: 'Admin', employee: 'Kandidat', company: 'Kompanija' }[
            role
          ],
          reset: (
            <IconButton
              key={`edit_${x.id}`}
              onClick={() =>
                setResetModal({ ...resetModal, open: true, id: x.id })
              }
              style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
            >
              <Replay fontSize="inherit" />
            </IconButton>
          ),
          delete: (
            <IconButton
              key={`archive_${x.id}`}
              style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
              onClick={() =>
                setDeleteModal({ ...deleteModal, open: true, id: x.id })
              }
            >
              <Delete fontSize="inherit" />
            </IconButton>
          )
        })
      );

      setUsers(mappedUsers);
      setIsLoading(false);
      setTotal(meta.total);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActiveCompanies({ ...state, ...sort, offset: (page - 1) * state.limit });
  }, [state, sort, page]);

  const onSelection = e => {
    setSelection(e);
  };

  useEffect(() => {
    if (users.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [users]);

  return (
    <main id="mainContent" className="xl-resize main-archived-candidates">
      <Title title="Aktivni korisnici" />
      <header>
        <div className="title-box">
          <h1>Arhivirani kandidati</h1>
          <p>Upravljanje arhiviranim kandidatima</p>
        </div>

        <div className="btn-wrapper">
          <Button
            className="button-inline"
            disabled={!selection.length}
            onClick={() => setBulkResetModal({ ...bulkResetModal, open: true })}
          >
            {/* <Email className="hide-on-mobile-sm" />
            Grupna poruka ({selection.length}) */}
            <Replay />
            Resetuj selektovano ({selection.length})
          </Button>
          {/* <button
            className="all-btns blue-btn upper btn-order-sm-last"
            onClick={() => {}}
          ></button> */}
          <Button
            grayscale
            className="button-inline"
            disabled={!selection.length}
            onClick={
              () => setBulkDeleteModal({ ...bulkDeleteModal, open: true })
              //setBulkArchiveModal({ ...bulkArchiveModal, open: true })
            }
          >
            <Delete className="hide-on-mobile-sm" />
            Obriši selektovano ({selection.length})
          </Button>
        </div>

        <div className="search-box-wrapper">
          <div className="wrapper justify-between w-select-10">
            <div className="select-wrapper select-row-wrapper">
              <select
                className="select select-row-num"
                name="num"
                onChange={e =>
                  setState({ ...state, limit: Number(e.target.value) })
                }
              >
                <option value="5" defaultValue>
                  Prikaži 5
                </option>
                <option value="10">Prikaži 10</option>
                <option value="25">Prikaži 25</option>
                <option value="50">Prikaži 50</option>
              </select>
              <DropdownIcon2 className="select-arrow" />
            </div>
          </div>

          <div className="wrapper max-width-search-input">
            <div className="search-wrapper">
              <CustomInput2
                label=""
                placeholder="Pronađi korisnika..."
                name="search"
                onChange={handleSearch}
                value={search}
                className="input-class"
                type="text"
              />
              <button className="search">
                <SearchIcon fill="currentColor" />
              </button>
            </div>
            <button
              className="settings-btn"
              onClick={() => setFilterModal({ ...filterModal, open: true })}
            >
              <FiltersIcon fill="currentColor" />
            </button>
          </div>
        </div>
      </header>

      <NewTable
        onSelection={onSelection}
        head={[
          { key: 'id', id: 1, name: 'ID', sort: "id" },
          { key: 'full_name', id: 2, name: 'Naziv Kandidata', sort: "full_name" },
          { key: 'location', id: 3, name: 'Lokacija' },
          { key: 'role', id: 4, name: 'Uloga' },
          { key: 'updated_at', id: 5, name: 'Datum' },
          { key: 'reset', id: 6, name: 'Resetuj', center: true },
          { key: 'delete', id: 7, name: 'Obriši', center: true }
        ]}
        body={users}
        sort={sort}
        onRequestSort={onRequestSort}
        isLoading={isLoading}
      />

      <div className="pagination-right mt-20">
        {state ? (
          <Pagination
            count={Math.ceil(total / state.limit)}
            page={page}
            setPage={setPage}
          />
        ) : (
          ''
        )}
      </div>

      <Modal
        title="Filteri"
        open={filterModal.open}
        onClose={onFilterModalClose}
      >
        <FilterModal
          state={state}
          setState={setState}
          setPage={setPage}
          onClose={onFilterModalClose}
        />
      </Modal>

      <Modal
        title="Obriši"
        open={deleteModal.open}
        onClose={onDeleteModalClose}
        size="medium"
      >
        <DeleteModal deleteUsers={deleteUser} onClose={onDeleteModalClose} />
      </Modal>

      <Modal
        title="Obriši"
        open={bulkDeleteModal.open}
        onClose={onBulkDeleteModalClose}
        size="medium"
      >
        <DeleteModal
          deleteUsers={deleteUsers}
          onClose={onBulkDeleteModalClose}
        />
      </Modal>

      <Modal
        title="Resetuj"
        open={resetModal.open}
        onClose={onResetModalClose}
        size="medium"
      >
        <ResetModal resetUsers={reset} onClose={onResetModalClose} />
      </Modal>

      <Modal
        title="Resetuj"
        open={bulkResetModal.open}
        onClose={onBulkResetModalClose}
        size="medium"
      >
        <ResetModal resetUsers={bulkReset} onClose={onBulkResetModalClose} />
      </Modal>
    </main>
  );
};

const FilterModal = ({ state, setState, setPage, onClose }) => {
  const [values, setValues] = useState({
    city_id: '',
    country_id: ''
  });

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const getCountries = async () => {
    const res = await GlobalAPI.countries();
    const formattedCountries = res.map(x => ({ value: x.id, label: x.name }));
    setCountries(formattedCountries);
  };

  const getCities = async country_id => {
    if (country_id) {
      setValues({ ...values, country_id });
      const res = await GlobalAPI.cities(country_id);
      const formattedCities = res.map(x => ({ value: x.id, label: x.name }));
      setCities(formattedCities);
      console.log(res);
    } else {
      setCities([]);
      setValues({ ...values, country_id: '', city_id: '' });
    }
  };

  const filter = () => {
    onClose();
    setPage(1);
    setState({ ...state, ...values });
  };

  const resetFilters = () => {
    onClose();
    setPage(1);
    setState({ ...state, country_id: null, city_id: null });
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Fragment>
      <Select
        className="force-space-b"
        label="Izaberite državu"
        value={values.country_id}
        onChange={country_id => getCities(country_id)}
        options={countries}
      />
      <Select
        className="force-space-b"
        label="Izaberite grad"
        value={values.city_id}
        onChange={city_id => setValues({ ...values, city_id })}
        options={cities}
      />
      <Button className="force-space-b" onClick={filter}>
        Filtriranje
      </Button>
      <Button variant="text" onClick={resetFilters}>
        Resetuj Filtere
      </Button>
    </Fragment>
  );
};

const DeleteModal = ({ onClose, deleteUsers }) => {
  const processDelete = async () => {
    await deleteUsers();
    onClose(0);
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da želite obrisati izabrane stavke?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processDelete}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

const ResetModal = ({ onClose, resetUsers }) => {
  const processReset = async () => {
    await resetUsers();
    onClose();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da želite resetovati izabrane stavke?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processReset}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

export default ArchivedCandidates;
