import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import {
  DropdownIcon2,
  FiltersIcon,
  FolderIcon,
  PenIcon,
  PlusIcon,
  SearchIcon,
  ProfileIcon
} from '../../components/Icons';
import { CustomDropdown, CustomInput2 } from '../../components/Inputs';
import AddUser from './modals/add-user';
import Confirmation from './modals/confirmation';
import Filter from './modals/filter';
import Pagination from '../../components/pagination/index';
import NewTable from '../../components/table/index';
import { Title } from '../../components/Title';
import { AdminAPI, CompanyAPI } from '../../api/v2';
import moment from 'moment';
import Switch from '../../components/switch';
import Modal from '../../components/modal';
import { IconButton } from '@material-ui/core';
import { AddCircle, EditOutlined, Folder } from '@material-ui/icons';
import { Fragment } from 'react';
import Select from '../../components/select';
import Button from '../../components/button/index';
import { useRef } from 'react';
import Input from '../../components/input';
import { toast } from 'react-toastify';
import CreateUserModal from "./modals/add-user";

var searchTimeout;

const ActiveUsers = () => {
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [total, setTotal] = useState(0);
  const [selection, setSelection] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    limit: 5,
    offset: 0,
    term: '',
    is_archived: 0
  });
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    order_dir: 'asc',
    order_by: 'id'
  });

  // MODALS
  const [filterModal, setFilterModal] = useState({
    open: false
  });
  const onFilterModalClose = () =>
    setFilterModal({ ...filterModal, open: false });

  // MODALS
  const [archiveModal, setArchiveModal] = useState({
    open: false,
    archiveId: 0
  });
  const onArchiveModalClose = () =>
    setArchiveModal({ ...archiveModal, open: false });

  // MODALS
  const [bulkArchiveModal, setBulkArchiveModal] = useState({
    open: false,
    archiveIds: []
  });
  const onBulkArchiveModalClose = () =>
    setBulkArchiveModal({ ...bulkArchiveModal, open: false });

  // MODALS
  const [createUserModal, setCreateUserModal] = useState({
    open: false,
    archiveIds: []
  });
  const onCreateUserModalClose = () => {
    setCreateUserModal({ ...createUserModal, open: false });
    setEditUser(null)
  }

  const searchRef = useRef();
  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  let history = useHistory();

  const handleSearch = e => {
    setSearch(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setState({ ...state, term: searchRef.current });
    }, 300);
  };

  const { user } = useSelector(state => state.global);

  const onRequestSort = (e, key) => {
    setSort(
      sort.order_by === key
        ? { ...sort, order_dir: sort.order_dir === 'asc' ? 'desc' : 'asc' }
        : { ...sort, order_by: key, order_dir: 'asc' }
    );
  };

  const archive = async () => {
    await AdminAPI.userSetArchived(archiveModal.archiveId, true);
    setState({ ...state });
  };

  const getActiveUsers = async params => {
    try {
      setIsLoading(true);
      const { data, meta } = await AdminAPI.users(params);

      const mappedUsers = data.map(
        ({ created_at, full_name, role, email, is_hidden, ...x }) => ({
          ...x,
          full_name: <p>{full_name}</p>,
          email: <a href={`mailto: ${email}`}>{email}</a>,
          created_at: moment(created_at).format('DD-MM-YYYY'),
          role: { admin: 'Admin', employee: 'Kandidat', company: 'Kompanija' }[
            role
          ],
          status: (
            <Switch
              key={`switch_${x.id}`}
              checked={!!is_hidden}
              setChecked={e => setHidden(e, x)}
              disabled={role === 'admin'}
            />
          ),
          edit: (
            <IconButton
              key={`edit_${x.id}`}
              disabled={role === 'admin'}
              onClick={() => {
                setEditUser({
                  full_name: full_name,
                  email: email,
                  role: role,
                  id: x.id
                });
              }}
              style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
            >
              <EditOutlined fontSize="inherit" />
            </IconButton>
          ),
          archive: (
            <IconButton
              key={`archive_${x.id}`}
              style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
              onClick={() =>
                setArchiveModal({ ...archiveModal, open: true, archiveId: x.id })
              }
              disabled={role === 'admin'}
            >
              <Folder fontSize="inherit" />
            </IconButton>
          )
        })
      );

      setUsers(mappedUsers);
      setIsLoading(false);
      setTotal(meta.total);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActiveUsers({ ...state, ...sort, offset: (page - 1) * state.limit });
  }, [state, sort, page]);

  const setHidden = async (e, x) => {
    await AdminAPI.userSetHidden(x.id, e ? 1 : 0, user.id);
  };

  const goTo = x => history.push(x);

  const onSelection = e => {
    setSelection(e);
  };

  const bulkArchive = async () => {
    await AdminAPI.usersSetArchived(selection.map(x => x.id));
    setState({ ...state, offset: 0 });
    setSelection([]);
  };

  useEffect(() => {
    if (users.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [users])

  return (
    <main id="mainContent" className="xl-resize main-active-users">
      <Title title="Aktivni korisnici" />
      <header>
        <div className="title-box">
          <h1>Korisnici</h1>
          <p>Upravljanje svim korisnicima</p>
        </div>

        <div className="btn-wrapper">
          <Button
            className="button-inline"
            onClick={() =>
              setCreateUserModal({ ...createUserModal, open: true })
            }
          >
            <AddCircle className="hide-on-mobile-sm" />
            Dodajte korisnika
          </Button>
          <Button
            grayscale
            className="button-inline"
            disabled={!selection.length}
            onClick={() =>
              setBulkArchiveModal({ ...bulkArchiveModal, open: true })
            }
          >
            <Folder className="hide-on-mobile-sm" />
            Arhiviraj selektovano ({selection.length})
          </Button>
        </div>

        <div className="search-box-wrapper">
          <div className="wrapper justify-between w-select-10">
            <div className="select-wrapper select-row-wrapper">
              <select
                className="select select-row-num"
                name="num"
                onChange={e =>
                  setState({ ...state, limit: Number(e.target.value) })
                }
              >
                <option value="5" defaultValue>
                  Prikaži 5
                </option>
                <option value="10">Prikaži 10</option>
                <option value="25">Prikaži 25</option>
                <option value="50">Prikaži 50</option>
              </select>
              <DropdownIcon2 className="select-arrow" />
            </div>
          </div>

          <div className="wrapper max-width-search-input">
            <div className="search-wrapper">
              <CustomInput2
                label=""
                placeholder="Pronađi korisnika..."
                name="search"
                onChange={handleSearch}
                value={search}
                className="input-class"
                type="text"
              />
              <button className="search">
                <SearchIcon fill="currentColor" />
              </button>
            </div>
            <button
              className="settings-btn"
              onClick={() => setFilterModal({ ...filterModal, open: true })}
            >
              <FiltersIcon fill="currentColor" />
            </button>
          </div>
        </div>
      </header>

      <NewTable
        onSelection={onSelection}
        head={[
          { key: 'id', id: 1, name: 'ID', sort: 'id' },
          {
            key: 'full_name',
            id: 2,
            name: 'Naziv korisnika',
            sort: 'full_name'
          },
          { key: 'email', id: 3, name: 'Email', sort: 'email' },
          { key: 'role', id: 4, name: 'Uloga', sort: 'role' },
          { key: 'created_at', id: 5, name: 'Datum', sort: 'created_at' },
          {
            key: 'status',
            id: 6,
            name: 'Status',
            center: true,
            sort: 'status'
          },
          {
            key: 'edit',
            id: 7,
            name: 'Izmjeni',
            center: true,
          },
          { key: 'archive', id: 8, name: 'Arhiviraj', center: true }
        ]}
        body={users}
        sort={sort}
        onRequestSort={onRequestSort}
        isLoading={isLoading}
      />

      <div className="pagination-right mt-20">
        {state ? (
          <Pagination
            count={Math.ceil(total / state.limit)}
            page={page}
            setPage={setPage}
          />
        ) : (
          ''
        )}
      </div>

      <Modal
        title="Filteri"
        open={filterModal.open}
        onClose={onFilterModalClose}
      >
        <FilterModal
          state={state}
          setState={setState}
          setPage={setPage}
          onClose={onFilterModalClose}
        />
      </Modal>

      <Modal
        title="Arhiviranje"
        open={archiveModal.open}
        onClose={onArchiveModalClose}
        size="medium"
      >
        <ArchiveModal archive={archive} onClose={onArchiveModalClose} />
      </Modal>

      <Modal
        title="Arhiviranje"
        open={bulkArchiveModal.open}
        onClose={onBulkArchiveModalClose}
        size="medium"
      >
        <ArchiveModal archive={bulkArchive} onClose={onBulkArchiveModalClose} />
      </Modal>

      <Modal
        title={editUser ? 'Izmjeni korisnika' : 'Novi korisnik'}
        open={createUserModal.open || editUser}
        onClose={onCreateUserModalClose}
        size="medium"
      >
        <CreateUserModal
          onClose={onCreateUserModalClose}
          state={state}
          setState={setState}
          user={editUser}
        />
      </Modal>
    </main>
  );
};

const FilterModal = ({ state, setState, setPage, onClose }) => {
  const [value, setValue] = useState('');

  const filter = () => {
    onClose();
    setPage(1);
    setState({ ...state, role: value });
  };

  const resetFilters = () => {
    onClose();
    setPage(1);
    setState({ ...state, role: null });
  };

  return (
    <Fragment>
      <Select
        className="force-space-b"
        label="Uloga korisnika"
        value={value}
        onChange={v => setValue(v)}
        options={[
          { value: 'admin', label: 'Admin' },
          { value: 'company', label: 'Kompanija' },
          { value: 'employee', label: 'Kandidat' }
        ]}
      />
      <Button className="force-space-b" onClick={filter}>
        Filtriranje
      </Button>
      <Button variant="text" onClick={resetFilters}>
        Resetuj Filtere
      </Button>
    </Fragment>
  );
};

const ArchiveModal = ({ onClose, archive }) => {
  const processArchive = async () => {
    await archive();
    onClose();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da želite da arhivirate izabrane stavke?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processArchive}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

export default ActiveUsers;
