import React, { Fragment, useEffect, useState } from "react";
import { CandidateAPI } from "../../../../api/v2";
import Button from "../../../../components/button/index";
import DataDisplay from "../../../../components/custom/data-display";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import Select from "../../../../components/select";
import Input from "../../../../components/ui/input";
import DatePicker from "../../../../components/datepicker/index";
import { Checkbox } from "@mui/material";
import Modal from "../../../../components/custom/modal";
import '../styles.scss'

const EducationsModal = ({ edu, open, onClose, addings, userId, ...parent }) => {
  const [educations, setEducations] = useState(edu);
  const [state, setState] = useState({
    id: 0,
    city: "",
    education_level_id: "",
    education_area_id: "",
    institution: "",
    title: "",
    course: "",
    start_date: null,
    end_date: null,
    ongoing: 0,
    profile_completely: 0,
  });

  useEffect(() => {
    setEducations(edu);
  }, [edu]);

  const [educationLevels, setEducationLevels] = useState([]);
  const [educationAreas, setEducationAreas] = useState([]);

  const clearFields = () => {
    setState({
      id: 0,
      city: "",
      education_level_id: "",
      education_area_id: "",
      institution: "",
      title: "",
      course: "",
      start_date: null,
      end_date: null,
      ongoing: 0,
    });
  };

  useEffect(() => {
    setEducationLevels(
      addings?.education_levels?.map((x) => ({ value: x.id, label: x.name }))
    );
    setEducationAreas(
      addings?.education_areas?.map((x) => ({ value: x.id, label: x.name }))
    );
  }, [addings]);

  const handleCheckbox = (e, a) => {
    setState({ ...state, ongoing: a ? 1 : 0 });
  };

  const [deleteOpen, setDeleteOpen] = useState(null);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setDeleteOpen(id);
  };

  const loadEdu = (x) => {
    setState({ ...x });
  };

  const addEdu = async () => {
    try {
      const { end_date, id, ...other } = state;
      if (id === 0) {
        const res = await CandidateAPI.educationStore({
          ...other,
          ...(!other.ongoing ? { end_date } : {}),
        }, userId);
        parent.setState({
          ...parent.state,
          educations: [...parent.state.educations, res?.data?.data],
        });
        clearFields();
      } else {
        const res = await CandidateAPI.educationUpdate(id, {
          ...other,
          ...(!other.ongoing ? { end_date } : {}),
        }, userId);
        parent.setState({
          ...parent.state,
          educations: parent.state.educations.map((x) =>
            x.id !== id ? x : res?.data?.data
          ),
        });
        clearFields();
      }
    } catch (e) {}
  };

  const deleteEdu = async () => {
    await CandidateAPI.educationDelete(deleteOpen, userId);
    parent.setState({
      ...parent.state,
      educations: parent.state.educations.filter((x) => x.id !== deleteOpen),
    });
    if (deleteOpen === state.id) {
      clearFields();
    }
    setDeleteOpen(null);
  };

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={"OBRAZOVANJE"}
        size="large"
        actions={[
          <Button
            grayscale={'true'}
            variant="contained"
            size="large"
            fullWidth
            onClick={clearFields}
            key={"OČISTI POLJA"}
          >
            {"OČISTI POLJA"}
          </Button>,
          <Button
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            onClick={addEdu}
            key={"SAČUVAJ"}
          >
            {state.id === 0 ? "DODAJ NOVO OBRAZOVANJE" : "SAČUVAJ"}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-profile-edit-modal-main">
          <div className="cv-dashboard-profile-edit-modal-side">
            {educations?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className={`cv-dashboard-profile-edit-single ${
                  x.id === state.id ? "cv-dashboard-profile-edit-active" : ""
                }`}
                label={`Obrazovanje ${y + 1}`}
                value={x.title}
                onClick={() => loadEdu(x)}
                action={
                  <IconButton onClick={(e) => handleDelete(e, x.id)}>
                    <Delete />
                  </IconButton>
                }
              />
            ))}
          </div>
          <div className="cv-dashboard-profile-edit-modal-side">
            <div className="cv-dashboard-profile-edit-modal-grid">
              <Select
                className="cv-dashboard-profile-col-double"
                label={"Nivo obrazovanja"}
                value={state.education_level_id}
                onChange={(education_level_id) =>
                  setState({ ...state, education_level_id })
                }
                color="secondary"
                variant="outlined"
                options={educationLevels}
                required
              />
              <Select
                label={"Oblast obrazovanja"}
                value={state.education_area_id}
                onChange={(education_area_id) =>
                  setState({ ...state, education_area_id })
                }
                color="secondary"
                variant="outlined"
                options={educationAreas}
                required
              />
              <Input
                label={"Stečeno zvanje"}
                value={state.title}
                onChange={(title) => setState({ ...state, title })}
                color="secondary"
                variant="outlined"
                required
              />
              <Input
                label={"Naziv institucije"}
                value={state.institution}
                onChange={(institution) => setState({ ...state, institution })}
                color="secondary"
                variant="outlined"
                required
              />
              <Input
                label={"Grad"}
                value={state.city}
                onChange={(city) => setState({ ...state, city })}
                color="secondary"
                variant="outlined"
                required
              />
              <Input
                className="cv-dashboard-profile-col-double"
                label={"Smjer"}
                value={state.course}
                onChange={(course) => setState({ ...state, course })}
                color="secondary"
                variant="outlined"
                required
              />
              <DatePicker
                className="cv-dashboard-profile-col-double"
                label={"Datum upisa"}
                value={state.start_date}
                onChange={(start_date) => setState({ ...state, start_date })}
                color="secondary"
                variant="outlined"
                required
              />
              {!state.ongoing ? (
                <DatePicker
                  className="cv-dashboard-profile-col-double"
                  label={"Datum diplomiranja"}
                  value={state.end_date}
                  onChange={(end_date) => setState({ ...state, end_date })}
                  color="secondary"
                  variant="outlined"
                  minDate={state.start_date}
                  required
                />
              ) : null}
              <div className="cv-dashboard-profile-col-double cv-dashboard-profile-end-date-in-process">
                <label>
                  <Checkbox
                    color="secondary"
                    onChange={handleCheckbox}
                    checked={!!state.ongoing}
                  />{" "}
                  {"Još uvijek u toku"}
                </label>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={typeof deleteOpen === 'number'}
        onClose={() => setDeleteOpen(null)}
        title={"OBRIŠI"}
        size="medium"
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            key={"OBRIŠI"}
            onClick={deleteEdu}
          >
            {"OBRIŠI"}
          </Button>,
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            key={"OTKAŽI"}
            onClick={() => setDeleteOpen(null)}
          >
            {"OTKAŽI"}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-cvvideo-modal-content">
          {"Da li ste sigurni da želite da obrišete obrazovanje" + "?"}
        </div>
      </Modal>
    </Fragment>
  );
};
export default EducationsModal;
