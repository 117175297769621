import { IconButton } from '@material-ui/core';
import { Close, CloudUpload, Delete, Edit, GetApp, Replay, Save } from '@material-ui/icons';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { AdminAPI, CandidateAPI, CompanyAPI, GlobalAPI } from '../../api/v2';
import Button from '../../components/button/index';
import DatePicker from '../../components/datepicker/index';
import Input from '../../components/input';
import InputCard from '../../components/inputcard';
import RichText from '../../components/RichText';
import Select from '../../components/select';
import { Title } from '../../components/Title';
import localizedStrings from '../../utils/localizedStrings';
import Modal from '../../components/modal/index';
import { toast } from 'react-toastify';
import moment from 'moment';
import { apiBaseUrl } from '../../api/config';

const EditCompany = () => {
  const [values, setValues] = useState({

    full_name: '',
    company_activity: '',
    description: '',
    website: '',
    facebook: '',
    instagram: '',
    country_id: '',
    city_id: '',
    address: '',
    pib: '',
    pdv: '',
    contact_person: '',
    contact_person_position: '',
    contact_mail: '',
    contact_phone: '',
    email: ''
  });

  const [files, setFiles] = useState({
    profile_image: '',
    background_image: '',
    company_video: ''
  });

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const onDeleteModalClose = () => setDeleteModal(false);

  const [cancelModal, setCancelModal] = useState(false);
  const onCancelModalClose = () => setCancelModal(false);

  const [saveModal, setSaveModal] = useState(false);
  const onSaveModalClose = () => setSaveModal(false);

  const getCountries = async () => {
    const res = await GlobalAPI.countries();
    const formattedCountries = res.map(x => ({ value: x.id, label: x.name }));
    setCountries(formattedCountries);
  };

  const getCities = async country_id => {
    if (country_id) {
      setValues({ ...values, country_id });
      const res = await GlobalAPI.cities(country_id);
      const formattedCities = res.map(x => ({ value: x.id, label: x.name }));
      setCities(formattedCities);
    } else {
      setCities([]);
      setValues({ ...values, country_id: '', city_id: '' });
    }
  };

  const { id } = useParams();

  const [loaded, setLoaded] = useState(false);

  const history = useHistory();

  const goTo = x => {
    history.push(x);
  };

  const getInfo = async () => {
    const {
      full_name,
      company_activity,
      company_description,
      website,
      facebook,
      instagram,
      country,
      city,
      address,
      pib,
      pdv,
      background_image,
      profile_image,
      company_video,
      company_users,
      email
    } = await CompanyAPI.companyInfo(id);
    await getCountries();
    await getCities(country.id);
    setValues({
      full_name,
      company_activity,
      description: company_description,
      website,
      facebook,
      instagram,
      country_id: country.id,
      city_id: city.id,
      address,
      pib,
      pdv,
      contact_person: company_users[0]?.contact_person || '',
      contact_mail: company_users[0]?.contact_mail || '',
      contact_person_position: company_users[0]?.contact_person_position || '',
      contact_phone: company_users[0]?.contact_phone || '',
      email: email || ''
    });
    setFiles({
      profile_image,
      background_image,
      company_video
    })
    setLoaded(true);
  };

  useEffect(() => {
    getInfo();
  }, []);

  const deleteCompany = async () => {
    try {
      await AdminAPI.userDelete(id);
      toast.success('Kompanija je uspešno obrisana');
      history.push('/active-companies');
    } catch (e) {
      toast.error('Došlo je do greške');
    }
  };

  const cancel = () => {
    history.push(`/company/${id}/preview`);
  }


  const update = async () => {
    try {
      await CompanyAPI.update(values ,id);
      toast.success('Kompanija je uspešno sačuvana');
      history.push(`/company/${id}/preview`);
    } catch (e) {
      toast.error('Došlo je do greške');
    }
  };

  const changeBackground = async (e) => {
    try {
      const { background_image  } = await CompanyAPI.updateBackgroundImage(
        e.target.files[0],
        id
      );
      toast.success('Pozadina je uspešno promenjena');
      setFiles({ ...files, background_image });
    } catch (e) {
      toast.error('Došlo je do greške');
    }
  }

  const changeProfile = async (e) => {
    try {
      const { profile_image } = await CompanyAPI.updateProfileImage(
        e.target.files[0],
        id
      );
      toast.success('Profilna slika je uspešno promenjena');
      setFiles({ ...files, profile_image });
    } catch (e) {
      toast.error('Došlo je do greške');
    }
  }


  const profileRef = useRef();
  const bgRef = useRef();
  const uploadRef = useRef();

  const uploadVideo = async (e) => {
    try {
      const { company_video } = await CompanyAPI.updateProfileVideo(
        e.target.files[0],
        id
      );
      toast.success('Video kompanije je uspešno promenjen');
      setFiles({ ...files, company_video });
    } catch (e) {
      toast.error('Došlo je do greške');
    }
  }

  return (
    <Fragment>
      <main id="mainContent" className="xl-resize">
        <Title title="Izmena kompanije" />
        <header>
          <div className="title-box">
            <h1>Izmena kompanije</h1>
            <p>Izmena informacija o kompaniji</p>
          </div>
        </header>

        <div className="add-ad-container">
          <h2 className="title">Pregled kompanije</h2>
          {loaded ? (
            <Fragment>
              <div className="candidate-main">
                <div className="company-background-holder">
                  <div className="company-background">
                    <img src={files.background_image} alt={values.full_name} />
                    <IconButton
                      className="candidate-bg-image-change"
                      onClick={() => bgRef.current.click()}
                    >
                      <input
                        type="file"
                        hidden
                        onChange={changeBackground}
                        ref={bgRef}
                      />
                      <Edit />
                    </IconButton>
                  </div>
                  <div className="candidate-profile-row">
                    <div className="candidate-image-holder">
                      <img
                        src={
                          files.profile_image ||
                          '/images/mockup/profile-img-placeholder.png'
                        }
                        alt={values.full_name}
                      />
                      <IconButton
                        className="candidate-image-change"
                        onClick={() => profileRef.current.click()}
                      >
                        <input
                          type="file"
                          hidden
                          onChange={changeProfile}
                          ref={profileRef}
                        />
                        <Edit />
                      </IconButton>
                    </div>
                  </div>
                </div>
                <div className="candidate-row">
                  <div className="add-ad-half">
                    <div className="add-ad-full-info">
                      <Input
                        label="Naziv kompanije"
                        className="force-space-b"
                        value={values.full_name}
                        onChange={full_name =>
                          setValues({ ...values, full_name })
                        }
                      />
                      <Input
                        label="Delatnost kompanije"
                        className="force-space-b"
                        value={values.company_activity}
                        onChange={company_activity =>
                          setValues({ ...values, company_activity })
                        }
                      />
                      <InputCard
                        label="Opis kompanije"
                        className="force-space-b"
                      >
                        <RichText
                          value={values.description}
                          setValue={company_description =>
                            setValues({ ...values, company_description })
                          }
                        />
                      </InputCard>
                      <div className="add-ad-row-info">
                        <span>
                          Video
                          <div
                            className="upload-video-label"
                            onClick={() => uploadRef.current.click()}
                          >
                            <IconButton className="add-ad-row-actions-icon">
                              <input
                                type="file"
                                hidden
                                ref={uploadRef}
                                onChange={uploadVideo}
                              />
                              <CloudUpload />
                            </IconButton>
                            Upload video
                          </div>
                        </span>
                        <div className="candidate-video">
                          {files.company_video ? (
                            <video src={files.company_video} controls></video>
                          ) : (
                            <img src="/images/mockup/video-placeholder.png" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="add-ad-half">
                    <InputCard className="force-space-b" label="Informacije">
                      <Input
                        label="Kontakt osoba"
                        className="force-space-b"
                        value={values.contact_person}
                        onChange={contact_person =>
                          setValues({ ...values, contact_person })
                        }
                      />
                      <Input
                        label="Pozicija kontakt osobe"
                        className="force-space-b"
                        value={values.contact_person_position}
                        onChange={contact_person_position =>
                          setValues({ ...values, contact_person_position })
                        }
                      />
                      <Input
                        label="Kontakt telefon"
                        className="force-space-b"
                        value={values.contact_phone}
                        onChange={contact_phone =>
                          setValues({ ...values, contact_phone })
                        }
                      />
                      <Input
                        label="Kontakt email"
                        value={values.contact_mail}
                        onChange={contact_mail =>
                          setValues({ ...values, contact_mail })
                        }
                      />
                    </InputCard>
                    <Input
                      label="Web adresa"
                      className="force-space-b"
                      value={values.website}
                      onChange={website => setValues({ ...values, website })}
                    />
                    <Input
                      label="Facebook profil"
                      className="force-space-b"
                      value={values.facebook}
                      onChange={facebook => setValues({ ...values, facebook })}
                    />
                    <Input
                      label="Instagram profil"
                      className="force-space-b"
                      value={values.instagram}
                      onChange={instagram =>
                        setValues({ ...values, instagram })
                      }
                    />
                    <Select
                      label="Država"
                      className="force-space-b"
                      value={values.country_id}
                      onChange={getCities}
                      options={countries}
                    />
                    <Select
                      label="Grad"
                      className="force-space-b"
                      value={values.city_id}
                      onChange={city_id => setValues({ ...values, city_id })}
                      options={cities}
                    />
                    <Input
                      label="Adresa kompanije"
                      className="force-space-b"
                      value={values.address}
                      onChange={address => setValues({ ...values, address })}
                    />
                    <Input
                      label="PIB"
                      className="force-space-b"
                      value={values.pib}
                      onChange={pib => setValues({ ...values, pib })}
                    />
                    <Input
                      label="PDV"
                      className="force-space-b"
                      value={values.pdv}
                      onChange={pdv => setValues({ ...values, pdv })}
                    />
                  </div>
                </div>
              </div>
              <div className="preview-ad-actions">
                <div className="preview-ad-actions-left">
                  <Button
                    className="button-inline"
                    color="secondary"
                    onClick={() => setDeleteModal(true)}
                  >
                    <Delete className="hide-on-mobile-sm" />
                    Obriši kompaniju
                  </Button>
                </div>
                <div className="preview-ad-actions-right">
                  <Button
                    className="button-inline"
                    onClick={() => setSaveModal(true)}
                  >
                    <Save className="hide-on-mobile-sm" />
                    Sačuvaj izmene
                  </Button>
                  <Button
                    grayscale
                    className="button-inline"
                    onClick={() => setCancelModal(true)}
                  >
                    <Close className="hide-on-mobile-sm" />
                    Otkaži
                  </Button>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="edit-ad-loading">
              <h1>Kompanija se učitava</h1>
              <p>Molimo sačekajte</p>
            </div>
          )}
        </div>
      </main>
      <Modal
        title="Obriši kompaniju"
        open={deleteModal}
        onClose={onDeleteModalClose}
        size="medium"
      >
        <DeleteModal onSubmit={deleteCompany} onClose={onDeleteModalClose} />
      </Modal>
      <Modal
        title="Otkaži radnju"
        open={cancelModal}
        onClose={onCancelModalClose}
        size="medium"
      >
        <CancelModal onSubmit={cancel} onClose={onCancelModalClose} />
      </Modal>
      <Modal
        title="Otkaži radnju"
        open={saveModal}
        onClose={onSaveModalClose}
        size="medium"
      >
        <SaveModal onSubmit={update} onClose={onSaveModalClose} />
      </Modal>
    </Fragment>
  );
};

const DeleteModal = ({ onClose, onSubmit }) => {
  const processSubmit = async () => {
    await onSubmit();
    onClose();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da ste želite da izbrišete kompaniju?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processSubmit}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

const CancelModal = ({ onClose, onSubmit }) => {
  const processSubmit = async () => {
    await onSubmit();
    onClose();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da ste želite da otkažete izmene?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processSubmit}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

const SaveModal = ({ onClose, onSubmit }) => {
  const processSubmit = async () => {
    await onSubmit();
    onClose();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da ste želite da sačuvate izmene?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processSubmit}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

export default EditCompany;
