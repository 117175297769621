import { useState, useEffect, Fragment } from 'react';
import { CandidateAPI } from "../../../../api/v2";
import Modal from "../../../../components/custom/modal";
import Button from "../../../../components/button/index";
import Input from "../../../../components/ui/input";
import Select from "../../../../components/select";

const DrivingLicenceModal = ({ licence, open, onClose, addings, userId, ...parent }) => {
  const [state, setState] = useState({
    own_vehicle: "",
    drivers_licence_category_id: "",
    additional_info: "",
  });

  const [drivingCategories, setDrivingCategories] = useState([]);

  useEffect(() => {
    setDrivingCategories(
      addings.drivers_licence_categories.map((x) => ({
        value: x.id,
        label: x.name,
      }))
    );
  }, []);

  useEffect(() => {
    if (licence && licence[0]) setState({ ...state, ...licence[0] });
  }, [licence]);

  const saveLicences = async () => {
    const res = await CandidateAPI.updateDriverLicence(state, userId);
    parent.setState({ ...parent.state, driver_licences: res?.data?.data?.driver_licences });
    onClose();
  };

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={"VOZAČKA DOZVOLA"}
        size="medium"
        actions={[
          <Button
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            onClick={saveLicences}
            key={0}
          >
            {"SAČUVAJ"}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-profile-edit-modal-main">
          <div className="cv-dashboard-profile-grid cv-dashboard-profile-driving-licence">
            <Select
              label={"Vozačka dozvola"}
              value={state.drivers_licence_category_id}
              onChange={(drivers_licence_category_id) =>
                setState({ ...state, drivers_licence_category_id })
              }
              color="secondary"
              variant="outlined"
              options={drivingCategories}
              required
            />
            <Select
              label={"Sopstveno vozilo"}
              value={state.own_vehicle}
              onChange={(own_vehicle) => setState({ ...state, own_vehicle })}
              color="secondary"
              variant="outlined"
              options={[
                {
                  value: "1",
                  label: "Da",
                },
                {
                  value: "0",
                  label: "Ne",
                },
              ]}
              required
            />
            <Input
              className="cv-dashboard-profile-col-double"
              label={"Dodatne informacije"}
              value={state.additional_info}
              onChange={(additional_info) =>
                setState({ ...state, additional_info })
              }
              color="secondary"
              variant="outlined"
              multiline
              maxRows={10}
              minRows={4}
            />
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default DrivingLicenceModal;
