import { useState, useEffect, Fragment } from 'react';
import { CandidateAPI } from "../../../../api/v2";
import Modal from "../../../../components/custom/modal";
import Button from "../../../../components/button/index";
import DataDisplay from "../../../../components/custom/data-display";
import { Delete } from "@mui/icons-material";
import Input from "../../../../components/ui/input";
import Select from "../../../../components/select";
import DatePicker from "../../../../components/datepicker/index";
import { Checkbox } from "@mui/material";
import { IconButton } from "@material-ui/core";

const WorkExpModal = ({ workexps, open, onClose, addings, userId, ...parent }) => {
  const [workExps, setWorkExps] = useState(workexps);
  const [state, setState] = useState({
    id: 0,
    company_name: "",
    location: "",
    position: "",
    type_of_work_id: "",
    description: "",
    start_date: null,
    end_date: null,
    ongoing: 0,
  });

  useEffect(() => {
    setWorkExps(workexps);
  }, [workexps]);

  const [categories, setCategories] = useState([]);

  const clearFields = () => {
    setState({
      id: 0,
      company_name: "",
      location: "",
      position: "",
      type_of_work_id: "",
      description: "",
      start_date: null,
      end_date: null,
      ongoing: 0,
    });
  };

  useEffect(() => {
    setCategories(
      addings.type_of_works.map((x) => ({ value: x.id, label: x.name }))
    );
  }, []);

  const handleCheckbox = (e, a) => {
    setState({ ...state, ongoing: a ? 1 : 0 });
  };

  const [deleteOpen, setDeleteOpen] = useState(null);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setDeleteOpen(id);
  };

  const loadWorkExp = (x) => {
    setState({
      id: x.id,
      company_name: x.company_name || "",
      location: x.location || "",
      position: x.position || "",
      type_of_work_id: x.type_of_work_id || "",
      description: x.description || "",
      start_date: x.start_date || null,
      end_date: x.end_date || null,
      ongoing: x.ongoing || "",
    });
  };

  const addWorkExp = async () => {
    try {
      const { end_date, id, ...other } = state;
      if (id === 0) {
        const res = await CandidateAPI.workExperienceStore({
          ...other,
          ...(!other.ongoing ? { end_date } : {}),
        }, userId);
        parent.setState({
          ...parent.state,
          work_experiences: [...parent.state.work_experiences, res?.data?.data],
        });
        clearFields();
      } else {
        const res = await CandidateAPI.workExperienceUpdate(id, {
          ...other,
          ...(!other.ongoing ? { end_date } : {}),
        }, userId);
        parent.setState({
          ...parent.state,
          work_experiences: parent.state.work_experiences.map((x) =>
            x.id !== id ? x : res?.data?.data
          ),
        });
        clearFields();
      }
    } catch (e) {}
  };

  const deleteWorkExp = async () => {
    await CandidateAPI.workExperienceDelete(deleteOpen, userId);
    parent.setState({
      ...parent.state,
      work_experiences: parent.state.work_experiences.filter(
        (x) => x.id !== deleteOpen
      ),
    });
    if (deleteOpen === state.id) {
      clearFields();
    }
    setDeleteOpen(null);
  };

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title="RADNO ISKUSTVO"
        size="large"
        actions={[
          <Button
            grayscale={'true'}
            variant="contained"
            size="large"
            fullWidth
            key={"OČISTI POLJA"}
            onClick={clearFields}
          >
            {"OČISTI POLJA"}
          </Button>,
          <Button
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            key={"SAČUVAJ"}
            onClick={addWorkExp}
          >
            {state.id === 0 ? "DODAJ NOVO RADNO ISKUSTVO" : "SAČUVAJ"}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-profile-edit-modal-main">
          <div className="cv-dashboard-profile-edit-modal-side">
            {workExps?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className={`cv-dashboard-profile-edit-single ${
                  x.id === state.id ? "cv-dashboard-profile-edit-active" : ""
                }`}
                label={`${"Kompanija"} ${y + 1}`}
                value={x.company_name}
                onClick={() => loadWorkExp(x)}
                action={
                  <IconButton onClick={(e) => handleDelete(e, x.id)}>
                    <Delete />
                  </IconButton>
                }
              />
            ))}
          </div>
          <div className="cv-dashboard-profile-edit-modal-side">
            <div className="cv-dashboard-profile-edit-modal-grid">
              <Input
                label={"Kompanija"}
                value={state.company_name}
                onChange={(company_name) =>
                  setState({ ...state, company_name })
                }
                color="secondary"
                variant="outlined"
                required
              />
              <Input
                label={"Lokacija"}
                value={state.location}
                onChange={(location) => setState({ ...state, location })}
                color="secondary"
                variant="outlined"
                required
              />
              <Input
                label={"Radna pozicija"}
                value={state.position}
                onChange={(position) => setState({ ...state, position })}
                color="secondary"
                variant="outlined"
                required
              />
              <Select
                label={"Kategorija posla"}
                value={state.type_of_work_id}
                onChange={(type_of_work_id) =>
                  setState({ ...state, type_of_work_id })
                }
                color="secondary"
                variant="outlined"
                options={categories}
                required
              />
              <DatePicker
                className="cv-dashboard-profile-col-double"
                label={"Datum zaposlenja"}
                value={state.start_date}
                onChange={(start_date) => setState({ ...state, start_date })}
                color="secondary"
                variant="outlined"
                required
              />
              {!state.ongoing ? (
                <DatePicker
                  className="cv-dashboard-profile-col-double"
                  label={"Datum prestanka radnog odnosa"}
                  value={state.end_date}
                  onChange={(end_date) => setState({ ...state, end_date })}
                  color="secondary"
                  variant="outlined"
                  minDate={state.start_date}
                  required
                />
              ) : null}

              <Input
                className="cv-dashboard-profile-col-double"
                label={"Opis pozicije"}
                value={state.description}
                onChange={(description) => setState({ ...state, description })}
                color="secondary"
                variant="outlined"
                multiline
                maxRows={4}
                minRows={4}
                required
              />
              <div className="cv-dashboard-profile-col-double cv-dashboard-profile-end-date-in-process">
                <label>
                  <Checkbox
                    color="secondary"
                    onChange={handleCheckbox}
                    checked={!!state.ongoing}
                  />{" "}
                  {"Još uvijek u toku"}
                </label>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={typeof deleteOpen === "number"}
        onClose={() => setDeleteOpen(null)}
        title={"OBRIŠI"}
        size="medium"
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={deleteWorkExp}
            key={"OBRIŠI"}
          >
            {"OBRIŠI"}
          </Button>,
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setDeleteOpen(null)}
            key={"OTKAŽI"}
          >
            {"OTKAŽI"}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-cvvideo-modal-content">
          {"Da li ste sigurni da želite da obrišete radno iskustvo?"}
        </div>
      </Modal>
    </Fragment>
  );
};

export default WorkExpModal;
