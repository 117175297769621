import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DropdownIcon2, FiltersIcon, SearchIcon } from '../../components/Icons';
import { CustomInput2 } from '../../components/Inputs';
import Pagination from '../../components/pagination/index';
import NewTable from '../../components/table/index';
import { Title } from '../../components/Title';
import { AdminAPI, CompanyAPI, GlobalAPI } from '../../api/v2';
import moment from 'moment';
import Switch from '../../components/switch';
import Modal from '../../components/modal';
import { IconButton } from '@material-ui/core';
import {
  AddCircle,
  Delete,
  EditOutlined,
  Folder,
  Replay
} from '@material-ui/icons';
import { Fragment } from 'react';
import Select from '../../components/select';
import Button from '../../components/button/index';
import { useRef } from 'react';
import Input from '../../components/input';
import { toast } from 'react-toastify';

var searchTimeout;

const ArchivedAds = () => {
  const [ads, setAds] = useState([]);
  const [total, setTotal] = useState(0);
  const [selection, setSelection] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    limit: 5,
    offset: 0,
    term: '',
    is_archived: 1
  });
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    order_dir: 'asc',
    order_by: 'id'
  });

  // MODALS
  const [filterModal, setFilterModal] = useState({
    open: false
  });
  const onFilterModalClose = () =>
    setFilterModal({ ...filterModal, open: false });

  // MODALS
  const [resetModal, setResetModal] = useState({
    open: false,
    resetId: 0
  });
  const onResetModalClose = () => setResetModal({ ...resetModal, open: false });

  // MODALS
  const [bulkResetModal, setBulkResetModal] = useState({
    open: false
  });
  const onBulkResetModalClose = () =>
    setBulkResetModal({ ...bulkResetModal, open: false });

  // MODALS
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    deleteId: 0
  });
  const onDeleteModalClose = () =>
    setDeleteModal({ ...deleteModal, open: false });

  // MODALS
  const [bulkDeleteModal, setBulkDeleteModal] = useState({
    open: false
  });
  const onBulkDeleteModalClose = () =>
    setBulkDeleteModal({ ...bulkDeleteModal, open: false });

  const searchRef = useRef();
  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  let history = useHistory();

  const handleSearch = e => {
    setSearch(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setState({ ...state, term: searchRef.current });
    }, 300);
  };

  const { user } = useSelector(state => state.global);

  const onRequestSort = (e, key) => {
    setSort(
      sort.order_by === key
        ? { ...sort, order_dir: sort.order_dir === 'asc' ? 'desc' : 'asc' }
        : { ...sort, order_by: key, order_dir: 'asc' }
    );
  };

  const getActiveAds = async params => {
    try {
      setIsLoading(true);
      const { data, meta } = await AdminAPI.ads(params);

      const mappedAds = data.map(
        ({ title, country, city, company, type_of_work, updated_at, ...x }) => ({
          ...x,
          ad: <AdColum title={title} company={company} />,
          work_type: type_of_work?.name || '/',
          updated_at: moment(updated_at).format('DD-MM-YYYY'),
          reset: (
            <IconButton
              key={`edit_${x.id}`}
              style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
              onClick={() =>
                setResetModal({ ...resetModal, open: true, resetId: x.id })
              }
            >
              <Replay fontSize="inherit" />
            </IconButton>
          ),
          delete: (
            <IconButton
              key={`archive_${x.id}`}
              style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
              onClick={() =>
                setDeleteModal({ ...deleteModal, open: true, deleteId: x.id })
              }
            >
              <Delete fontSize="inherit" />
            </IconButton>
          )
        })
      );

      setAds(mappedAds);
      setIsLoading(false);
      setTotal(meta.total);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const removeFalsies = Object.fromEntries(
      Object.entries(state).filter(([_, v]) => v != null && v !== '')
    );

    getActiveAds({
      ...removeFalsies,
      ...sort,
      offset: (page - 1) * state.limit
    });
  }, [state, sort, page]);

  const onSelection = e => {
    setSelection(e);
  };

  useEffect(() => {
    if (ads.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [ads]);

  const deleteAd = async () => {
    await AdminAPI.deleteAd(deleteModal.deleteId);
    setState({ ...state });
  };

  const bulkDeleteAd = async () => {
    await AdminAPI.deleteAds(selection.map(x => x.id));
    setState({ ...state, offset: 0 });
    setSelection([]);
  };

  const resetAd = async () => {
    await AdminAPI.adReset(resetModal.resetId);
    setState({ ...state });
  };

  const bulkResetAd = async () => {
    await AdminAPI.adsReset(selection.map(x => x.id));
    setState({ ...state, offset: 0 });
    setSelection([]);
  };

  return (
    <main id="mainContent" className="xl-resize main-archived-ads">
      <Title title="Aktivni korisnici" />
      <header>
        <div className="title-box">
          <h1>Arhivirani oglasi</h1>
          <p>Upravljanje arhiviranim oglasima</p>
        </div>

        <div className="btn-wrapper">
          <Button
            className="button-inline"
            disabled={!selection.length}
            onClick={() => setBulkResetModal({ ...bulkResetModal, open: true })}
          >
            <Replay className="hide-on-mobile-sm" />
            Resetuj selektovano ({selection.length})
          </Button>
          <Button
            grayscale
            className="button-inline"
            disabled={!selection.length}
            onClick={() => setBulkDeleteModal({ ...bulkDeleteModal, open: true })}
          >
            <Delete className="hide-on-mobile-sm" />
            Obriši selektovano ({selection.length})
          </Button>
        </div>

        <div className="search-box-wrapper">
          <div className="wrapper justify-between w-select-10">
            <div className="select-wrapper select-row-wrapper">
              <select
                className="select select-row-num"
                name="num"
                onChange={e =>
                  setState({ ...state, limit: Number(e.target.value) })
                }
              >
                <option value="5" defaultValue>
                  Prikaži 5
                </option>
                <option value="10">Prikaži 10</option>
                <option value="25">Prikaži 25</option>
                <option value="50">Prikaži 50</option>
              </select>
              <DropdownIcon2 className="select-arrow" />
            </div>
          </div>

          <div className="wrapper max-width-search-input">
            <div className="search-wrapper">
              <CustomInput2
                label=""
                placeholder="Pretraga..."
                name="search"
                onChange={handleSearch}
                value={search}
                className="input-class"
                type="text"
              />
              <button className="search">
                <SearchIcon fill="currentColor" />
              </button>
            </div>
            <button
              className="settings-btn"
              onClick={() => setFilterModal({ ...filterModal, open: true })}
            >
              <FiltersIcon fill="currentColor" />
            </button>
          </div>
        </div>
      </header>

      <NewTable
        onSelection={onSelection}
        head={[
          { key: 'id', id: 1, name: 'ID', sort: "id" },
          { key: 'ad', id: 2, name: 'Naziv oglasa', sort: "title" },
          { key: 'work_type', id: 3, name: 'Tip posla' },
          { key: 'updated_at', id: 4, name: 'Datum' },
          { key: 'reset', id: 5, name: 'Resetuj', center: true },
          { key: 'delete', id: 6, name: 'Obriši', center: true }
        ]}
        body={ads}
        sort={sort}
        onRequestSort={onRequestSort}
        isLoading={isLoading}
      />

      <div className="pagination-right mt-20">
        {state ? (
          <Pagination
            count={Math.ceil(total / state.limit)}
            page={page}
            setPage={setPage}
          />
        ) : (
          ''
        )}
      </div>

      <Modal
        title="Filteri"
        open={filterModal.open}
        onClose={onFilterModalClose}
        size={'medium'}
      >
        <FilterModal
          state={state}
          setState={setState}
          setPage={setPage}
          onClose={onFilterModalClose}
        />
      </Modal>

      <Modal
        title="Obriši"
        open={deleteModal.open}
        onClose={onDeleteModalClose}
        size="medium"
      >
        <DeleteModal deleteAd={deleteAd} onClose={onDeleteModalClose} />
      </Modal>

      <Modal
        title="Obriši"
        open={bulkDeleteModal.open}
        onClose={onBulkDeleteModalClose}
        size="medium"
      >
        <DeleteModal deleteAd={bulkDeleteAd} onClose={onBulkDeleteModalClose} />
      </Modal>

      <Modal
        title="Resetuj"
        open={resetModal.open}
        onClose={onResetModalClose}
        size="medium"
      >
        <ResetModal resetAd={resetAd} onClose={onResetModalClose} />
      </Modal>

      <Modal
        title="Resetuj"
        open={bulkResetModal.open}
        onClose={onBulkResetModalClose}
        size="medium"
      >
        <ResetModal resetAd={bulkResetAd} onClose={onBulkResetModalClose} />
      </Modal>
    </main>
  );
};

const FilterModal = ({ state, setState, setPage, onClose }) => {
  const [values, setValues] = useState({
    country_id: '',
    city_id: '',
    type_of_work_id: '',
    user_id: '',
    status: ''
  });

  const { common } = useSelector(state => state.global);

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [companies, setCompanies] = useState([]);

  const getCountries = async () => {
    const res = await GlobalAPI.countries();
    const formattedCountries = res.map(x => ({ value: x.id, label: x.name }));
    setCountries(formattedCountries);
  };

  const getCities = async country_id => {
    if (country_id) {
      setValues({ ...values, country_id });
      const res = await GlobalAPI.cities(country_id);
      const formattedCities = res.map(x => ({ value: x.id, label: x.name }));
      setCities(formattedCities);
      console.log(res);
    } else {
      setCities([]);
      setValues({ ...values, country_id: '', city_id: '' });
    }
  };

  const filter = () => {
    onClose();
    setPage(1);
    setState({ ...state, ...values });
  };

  const resetFilters = () => {
    onClose();
    setPage(1);
    setState({ ...state, country_id: null, city_id: null });
  };

  const formatJobCategories = () => {
    const formattedJobCategories = common.type_of_works.map(x => ({
      value: x.id,
      label: x.name
    }));
    setJobCategories(formattedJobCategories);
  };

  const getCompanies = async () => {
    const res = await CompanyAPI.companies();
    const formattedCompanies = res.map(x => ({
      value: x.id,
      label: x.full_name
    }));
    setCompanies(formattedCompanies);
  };

  useEffect(() => {
    getCountries();
    formatJobCategories();
    getCompanies();
  }, []);

  return (
    <Fragment>
      <Select
        className="force-space-b"
        label="Država"
        value={values.country_id}
        onChange={country_id => getCities(country_id)}
        options={countries}
      />
      <Select
        className="force-space-b"
        label="Grad"
        value={values.city_id}
        onChange={city_id => setValues({ ...values, city_id })}
        options={cities}
      />
      <Select
        className="force-space-b"
        label="Kategorija posla"
        value={values.type_of_work_id}
        onChange={type_of_work_id => setValues({ ...values, type_of_work_id })}
        options={jobCategories}
      />
      <Select
        className="force-space-b"
        label="Naziv kompanije"
        value={values.user_id}
        onChange={user_id => setValues({ ...values, user_id })}
        options={companies}
      />
      {/* <Select
        className="force-space-b"
        label="Status"
        value={values.status}
        onChange={status => setValues({ ...values, status })}
        options={[
          { value: 1, label: 'Aktuelno' },
          { value: 6, label: 'Isteklo' }
        ]}
      /> */}
      <Button className="force-space-b" onClick={filter}>
        Filtriranje
      </Button>
      <Button variant="text" onClick={resetFilters}>
        Resetuj Filtere
      </Button>
    </Fragment>
  );
};

const DeleteModal = ({ onClose, deleteAd }) => {
  const processArchive = async () => {
    await deleteAd();
    onClose();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da želite da obrišete izabrane stavke?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processArchive}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

const ResetModal = ({ onClose, resetAd }) => {
  const processArchive = async () => {
    await resetAd();
    onClose();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da želite da resetujete izabrane stavke?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processArchive}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

const AdColum = ({ title, company }) => {
  return (
    <div className="oglas-col">
      <div className="oglas-col-img">
        <img alt={company.full_name} src={company.profile_image} />
      </div>
      <div className="oglas-col-text">
        <Link to={`/`}>{title}</Link>
        <div>{company.full_name}</div>
      </div>
    </div>
  );
};

export default ArchivedAds;
