import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DropdownIcon2, FiltersIcon, SearchIcon } from '../../components/Icons';
import { CustomInput2 } from '../../components/Inputs';
import Pagination from '../../components/pagination/index';
import NewTable from '../../components/table/index';
import { Title } from '../../components/Title';
import { AdminAPI, CompanyAPI, GlobalAPI } from '../../api/v2';
import moment from 'moment';
import Switch from '../../components/switch';
import Modal from '../../components/modal';
import { IconButton } from '@material-ui/core';
import { AddCircle, EditOutlined, Folder } from '@material-ui/icons';
import { Fragment } from 'react';
import Select from '../../components/select';
import Button from '../../components/button/index';
import { useRef } from 'react';
import Input from '../../components/input';
import { toast } from 'react-toastify';

var searchTimeout;

const ActiveAds = () => {
  const [ads, setAds] = useState([]);
  const [total, setTotal] = useState(0);
  const [selection, setSelection] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    limit: 5,
    offset: 0,
    term: '',
    is_archived: 0
  });
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    order_dir: 'asc',
    order_by: 'id'
  });

  // MODALS
  const [filterModal, setFilterModal] = useState({
    open: false
  });
  const onFilterModalClose = () =>
    setFilterModal({ ...filterModal, open: false });

  // MODALS
  const [archiveModal, setArchiveModal] = useState({
    open: false,
    archiveId: 0
  });
  const onArchiveModalClose = () =>
    setArchiveModal({ ...archiveModal, open: false });

  // MODALS
  const [bulkArchiveModal, setBulkArchiveModal] = useState({
    open: false,
    archiveIds: []
  });
  const onBulkArchiveModalClose = () =>
    setBulkArchiveModal({ ...bulkArchiveModal, open: false });

  // MODALS
  const [createUserModal, setCreateUserModal] = useState({
    open: false,
    archiveIds: []
  });
  const onCreateUserModalClose = () =>
    setCreateUserModal({ ...createUserModal, open: false });

  const searchRef = useRef();
  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  let history = useHistory();

  const handleSearch = e => {
    setSearch(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setState({ ...state, term: searchRef.current });
    }, 300);
  };

  const { user } = useSelector(state => state.global);

  const onRequestSort = (e, key) => {
    setSort(
      sort.order_by === key
        ? { ...sort, order_dir: sort.order_dir === 'asc' ? 'desc' : 'asc' }
        : { ...sort, order_by: key, order_dir: 'asc' }
    );
  };

  const archive = async () => {
    await AdminAPI.userSetArchived(archiveModal.archiveId, true);
    setState({ ...state });
  };

  const getActiveAds = async params => {
    try {
      setIsLoading(true);
      const {data, meta} = await AdminAPI.ads(params);

      const mappedAds = data.map(
        ({title, country, city, company, type_of_work, end_date, ...x}) => ({
          ...x,
          ad: <AdColum title={title} company={company} id={x.id}/>,
          work_type: type_of_work?.name || '/',
          location:
            [city?.name, country?.name]
              .filter(x => ![undefined, null, ''].includes(x))
              .join(', ') || '/',
          expires: moment(end_date).format('DD-MM-YYYY'),
          // status:
          //   {
          //     1: 'Aktivan',
          //     2: '',
          //     3: '',
          //     4: '',
          //     5: '',
          //     6: 'Istekao'
          //   }[status] || '/',
          edit: (
            <IconButton
              key={`edit_${x.id}`}
              onClick={() => goTo(`/ad/${x.id}/edit`)}
              style={{fontSize: 24, margin: '0 auto', display: 'block'}}
            >
              <EditOutlined fontSize="inherit"/>
            </IconButton>
          ),
          archive: (
            <IconButton
              key={`archive_${x.id}`}
              style={{fontSize: 24, margin: '0 auto', display: 'block'}}
              onClick={() =>
                setArchiveModal({...archiveModal, open: true, archiveId: x.id})
              }
            >
              <Folder fontSize="inherit"/>
            </IconButton>
          )
        })
      );

      setAds(mappedAds);
      setIsLoading(false);
      setTotal(meta.total);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const removeFalsies = Object.fromEntries(
      Object.entries(state).filter(([_, v]) => v != null && v !== '')
    );

    getActiveAds({
      ...removeFalsies,
      ...sort,
      offset: (page - 1) * state.limit
    });
  }, [state, sort, page]);

  const setHidden = async (e, x) => {
    await AdminAPI.userSetHidden(x.id, e ? 1 : 0, user.id);
  };

  const goTo = x => history.push(x);

  const onSelection = e => {
    setSelection(e);
  };

  const bulkArchive = async () => {
    await AdminAPI.usersSetArchived(selection.map(x => x.id));
    setState({ ...state, offset: 0 });
    setSelection([]);
  };

  useEffect(() => {
    if (ads.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [ads]);

  return (
    <main id="mainContent" className="xl-resize main-active-ads">
      <Title title="Aktivni korisnici" />
      <header>
        <div className="title-box">
          <h1>Oglasi</h1>
          <p>Upravljanje svim oglasima</p>
        </div>

        <div className="btn-wrapper">
          <Button className="button-inline" onClick={() => goTo('/create-ad')}>
            <AddCircle className="hide-on-mobile-sm" />
            Dodajte oglas
          </Button>
          <Button
            grayscale
            className="button-inline"
            disabled={!selection.length}
            onClick={() =>
              setBulkArchiveModal({ ...bulkArchiveModal, open: true })
            }
          >
            <Folder className="hide-on-mobile-sm" />
            Arhiviraj selektovano ({selection.length})
          </Button>
        </div>

        <div className="search-box-wrapper">
          <div className="wrapper justify-between w-select-10">
            <div className="select-wrapper select-row-wrapper">
              <select
                className="select select-row-num"
                name="num"
                onChange={e =>
                  setState({ ...state, limit: Number(e.target.value) })
                }
              >
                <option value="5" defaultValue>
                  Prikaži 5
                </option>
                <option value="10">Prikaži 10</option>
                <option value="25">Prikaži 25</option>
                <option value="50">Prikaži 50</option>
              </select>
              <DropdownIcon2 className="select-arrow" />
            </div>
          </div>

          <div className="wrapper max-width-search-input">
            <div className="search-wrapper">
              <CustomInput2
                label=""
                placeholder="Pretraga..."
                name="search"
                onChange={handleSearch}
                value={search}
                className="input-class"
                type="text"
              />
              <button className="search">
                <SearchIcon fill="currentColor" />
              </button>
            </div>
            <button
              className="settings-btn"
              onClick={() => setFilterModal({ ...filterModal, open: true })}
            >
              <FiltersIcon fill="currentColor" />
            </button>
          </div>
        </div>
      </header>

      <NewTable
        onSelection={onSelection}
        head={[
          { key: 'id', id: 1, name: 'ID', sort: 'id' },
          { key: 'ad', id: 2, name: 'Naziv oglasa', sort: 'title' },
          { key: 'work_type', id: 3, name: 'Tip posla' },
          { key: 'location', id: 4, name: 'Lokacija' },
          { key: 'expires', id: 5, name: 'Ističe' },
          { key: 'status', id: 6, name: 'Status', center: true },
          { key: 'edit', id: 7, name: 'Izmeni', center: true },
          { key: 'archive', id: 8, name: 'Arhiviraj', center: true }
        ]}
        body={ads}
        sort={sort}
        onRequestSort={onRequestSort}
        isLoading={isLoading}
      />

      <div className="pagination-right mt-20">
        {state ? (
          <Pagination
            count={Math.ceil(total / state.limit)}
            page={page}
            setPage={setPage}
          />
        ) : (
          ''
        )}
      </div>

      <Modal
        title="Filteri"
        open={filterModal.open}
        onClose={onFilterModalClose}
        size={'medium'}
      >
        <FilterModal
          state={state}
          setState={setState}
          setPage={setPage}
          onClose={onFilterModalClose}
        />
      </Modal>

      <Modal
        title="Arhiviranje"
        open={archiveModal.open}
        onClose={onArchiveModalClose}
        size="medium"
      >
        <ArchiveModal archive={archive} onClose={onArchiveModalClose} />
      </Modal>

      <Modal
        title="Arhiviranje"
        open={bulkArchiveModal.open}
        onClose={onBulkArchiveModalClose}
        size="medium"
      >
        <ArchiveModal archive={bulkArchive} onClose={onBulkArchiveModalClose} />
      </Modal>

      <Modal
        title="Novi korisnik"
        open={createUserModal.open}
        onClose={onCreateUserModalClose}
        size="medium"
      >
        <CreateUserModal
          onClose={onCreateUserModalClose}
          state={state}
          setState={setState}
        />
      </Modal>
    </main>
  );
};

const FilterModal = ({ state, setState, setPage, onClose }) => {
  const [values, setValues] = useState({
    country_id: '',
    city_id: '',
    type_of_work_id: '',
    user_id: '',
    status: ''
  });

  const { common } = useSelector(state => state.global);

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [companies, setCompanies] = useState([]);

  const getCountries = async () => {
    const res = await GlobalAPI.countries();
    const formattedCountries = res.map(x => ({ value: x.id, label: x.name }));
    setCountries(formattedCountries);
  };

  const getCities = async country_id => {
    if (country_id) {
      setValues({ ...values, country_id });
      const res = await GlobalAPI.cities(country_id);
      const formattedCities = res.map(x => ({ value: x.id, label: x.name }));
      setCities(formattedCities);
      console.log(res);
    } else {
      setCities([]);
      setValues({ ...values, country_id: '', city_id: '' });
    }
  };

  const filter = () => {
    onClose();
    setPage(1);
    setState({ ...state, ...values });
  };

  const resetFilters = () => {
    onClose();
    setPage(1);
    setState({ ...state, country_id: null, city_id: null });
  };

  const formatJobCategories = () => {
    const formattedJobCategories = common.type_of_works.map(x => ({
      value: x.id,
      label: x.name
    }));
    setJobCategories(formattedJobCategories);
  };

  const getCompanies = async () => {
    const res = await CompanyAPI.companies();
    const formattedCompanies = res.map(x => ({
      value: x.id,
      label: x.full_name
    }));
    setCompanies(formattedCompanies);
  };

  useEffect(() => {
    getCountries();
    formatJobCategories();
    getCompanies();
  }, []);

  return (
    <Fragment>
      <Select
        className="force-space-b"
        label="Država"
        value={values.country_id}
        onChange={country_id => getCities(country_id)}
        options={countries}
      />
      <Select
        className="force-space-b"
        label="Grad"
        value={values.city_id}
        onChange={city_id => setValues({ ...values, city_id })}
        options={cities}
      />
      <Select
        className="force-space-b"
        label="Kategorija posla"
        value={values.type_of_work_id}
        onChange={type_of_work_id => setValues({ ...values, type_of_work_id })}
        options={jobCategories}
      />
      <Select
        className="force-space-b"
        label="Naziv kompanije"
        value={values.user_id}
        onChange={user_id => setValues({ ...values, user_id })}
        options={companies}
      />
      <Select
        className="force-space-b"
        label="Status"
        value={values.status}
        onChange={status => setValues({ ...values, status })}
        options={[
          { value: 1, label: 'Aktivni' },
          { value: 2, label: 'Neaktivni' },
          { value: 3, label: 'Arhivirani' },
          { value: 4, label: 'Neobjavljeni' },
          { value: 5, label: 'Sačuvani' },
          { value: 6, label: 'Istekli' }
        ]}
      />
      <Button className="force-space-b" onClick={filter}>
        Filtriranje
      </Button>
      <Button variant="text" onClick={resetFilters}>
        Resetuj Filtere
      </Button>
    </Fragment>
  );
};

const ArchiveModal = ({ onClose, archive }) => {
  const processArchive = async () => {
    await archive();
    onClose();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da želite da arhivirate izabrane stavke?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processArchive}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

const CreateUserModal = ({ onClose, state, setState }) => {
  const [values, setValues] = useState({
    full_name: '',
    email: '',
    role: '',
    password: '',
    password_confirmation: ''
  });

  const submit = async () => {
    if (Object.keys(values).filter(x => values[x].trim() === '').length) {
      return toast.warning('Sva polja moraju biti popunjena');
    }

    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        values.email.trim()
      )
    ) {
      return toast.warning('Neispravan email');
    }

    if (values.password !== values.password_confirmation) {
      return toast.warning('Lozinke se ne podudaraju');
    }

    try {
      await AdminAPI.userCreate(values);
      onClose();
      setState({ ...state });
      return toast.success('Korisnik je kreiran');
    } catch (e) {
      return toast.error('Greska');
    }
  };

  return (
    <Fragment>
      <Input
        className="force-space-b"
        label="Naziv korisnika"
        value={values.full_name}
        onChange={full_name => setValues({ ...values, full_name })}
      />
      <Input
        className="force-space-b"
        label="Email adresa"
        value={values.email}
        onChange={email => setValues({ ...values, email })}
      />
      <Select
        className="force-space-b"
        label="Uloga korisnika"
        value={values.role}
        onChange={role => setValues({ ...values, role })}
        options={[
          { value: 'admin', label: 'Admin' },
          { value: 'company', label: 'Kompanija' },
          { value: 'employee', label: 'Kandidat' }
        ]}
      />
      <Input
        className="force-space-b"
        label="Lozinka"
        value={values.password}
        onChange={password => setValues({ ...values, password })}
        type="password"
      />
      <Input
        className="force-space-b"
        label="Ponovite lozinku"
        value={values.password_confirmation}
        onChange={password_confirmation =>
          setValues({ ...values, password_confirmation })
        }
        type="password"
      />
      <Button onClick={submit}>Sačuvajte</Button>
    </Fragment>
  );
};

const AdColum = ({ title, company, id }) => {
  return (
    <div className="oglas-col">
      <div className="oglas-col-img">
        <img alt={company.full_name} src={company.profile_image} />
      </div>
      <div className="oglas-col-text">
        <Link to={`/ad/${id}/preview`}>{title}</Link>
        <div>{company.full_name}</div>
      </div>
    </div>
  );
};

export default ActiveAds;
