import React from 'react';
import { Logo } from '../components/Icons';
import { Title } from '../components/Title';
import localizedStrings from '../utils/localizedStrings';

const NotFound = () => {
  const goBack = e => {
    window.history.back();
  };
  return (
    <div className="not-found-page">
      <div className="box-container m-container not-found-container bg-gradient-reverse">
        <Title title="404"/>
        <div className="mt-1 mb-5">
          <Logo width="250" height="70" />
        </div>
        <div className="mt-1 mb-5">
          <h2 className="not-found-title">404</h2>
          <p className="bold">{localizedStrings.notFound.page_not_found}</p>
          <p>{localizedStrings.notFound.try_again_later}</p>
        </div>
        <div className="mt-1 mb-3">
          <button className="not-found-btn" onClick={e => goBack(e)}>
            {localizedStrings.notFound.go_back}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
