import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Input from '../../components/ui/input/index';
import { AdminAPI, GlobalAPI } from '../../api/v2';
import Select from '../../components/select';
import Button from '../../components/button/index';
import moment from 'moment';
import { Fragment } from 'react';
import { globalActions } from '../../redux/v2/global';
import "./style.scss";
import {SimpleCard} from "../../components/ui";
import UploadImage from "../../components/custom/uploadimage";
import DataDisplay from "../../components/custom/data-display";
import { Lock } from "@mui/icons-material";
import {IconButton} from "@material-ui/core";
import DashTitle from "../../components/custom/dash-title";
import ChangePasswordModal from "./modals/change-password-modal";
// import NotificationsModal from "./modals/notifications-modal";

const initialLanguages = [
  { id: 1, name: "crnogorski", code: "ME"},
  { id: 2, name: "srpski", code: "RS" },
  { id: 3, name: "bosanski", code: "BA" },
  { id: 4, name: "hrvatski", code: "HR" },
  // { id: 5, name: "Slovenija", code: "SL", object: SL },
  // { id: 6, name: "Makedonija", code: "MK", object: MK },
  // { id: 7, name: "Engleski", code: "EN", object: EN },
];

const Settings = () => {
  const { user } = useSelector(state => state.global);
  const [view, setView] = useState(0);
  const [passwordModal, setPasswordModal] = useState(false);
  const [uiLanguages, setUiLanguages] = useState(initialLanguages);
  // const [notificationsModal, setNotificationsModal] = useState(false);

  const [values, setValues] = useState({
    full_name: user.full_name,
    email: user.email,
    ui_language: '1',
    language_id: user.language_id,
  });

  const resetDefaults = () => {
    setValues({
      ...values,
      email: user.email,
      full_name: user.full_name,
      language_id: user.language_id,
      profile_image: user.profile_image,
    });
  };

  const dispatch = useDispatch();

  const getUiLanguages = async () => {
    const res = await GlobalAPI.uiLanguages();
    setUiLanguages(res);
  };

  useEffect(() => {
    getUiLanguages();
  }, []);

  const updateInfo = async () => {
    if (Object.keys(values).filter(x => !x.trim()).length) {
      return toast.warn('Popunite sva polja');
    }
    try {
      const res = await AdminAPI.update(values);
      setView(false);
      toast.success('Izmene su sačuvane');
      dispatch(globalActions.setUser({ ...user, ...res }));
    } catch (e) {
      toast.error('Došlo je do greške');
    }
  };

  const uploadImage = async files => {
    const file = files[0];
    const res = await AdminAPI.updateProfileImage(file);
    dispatch(globalActions.setUser({ ...user, ...res }));
    toast.success('Izmene su sačuvane');
  };


  // const [notificationsData, setNotificationsData] = useState({
  //   locations: user.city_notifications,
  //   categories: user.type_of_work_notifications,
  //   turn_notification: !!user.turn_notification,
  // });

  return (
    <main id="mainContent">
      <Fragment>
        <div className="cv-dashboard-page-scrollable">
          <DashTitle
            title={"Podešavanja"}
          />
          <div className="cv-dashboard-settings-content ">
            <SimpleCard
              withContent
              className="cv-dashboard-settings-content-side"
            >
              <div className="cv-dashboard-settings-avatar-wrap">
                <div>
                  <UploadImage
                    src={user.profile_image}
                    name={values.full_name}
                    onUpload={uploadImage}
                    className="cv-dashboard-settings-upload"
                  />
                </div>
                <div className="cv-data-display-label">
                  {"Izmjena avatara (maks. dim. 300x300)"}
                </div>
              </div>
              <div className="cv-dashboard-profile-grid">
                <Input
                  className="cv-dashboard-profile-col-double"
                  label={"Ime korisnika"}
                  value={values?.full_name}
                  disabled={!view}
                  color="secondary"
                  onChange={(full_name) => setValues({ ...values, full_name })}
                />
                <Input
                  className="cv-dashboard-profile-col-double"
                  label={"Email"}
                  value={values.email}
                  disabled={!view}
                  color="secondary"
                  onChange={(email) => setValues({ ...values, email })}
                />
              <Select
                  className="cv-dashboard-profile-col-double"
                  label={"Jezik"}
                  value={values.language_id}
                  disabled={!view}
                  options={uiLanguages.map((lang) => ({
                    value: lang.id,
                    label: lang.name,
                  }))}
                  color="secondary"
                  onChange={(language_id) => {
                    setValues({ ...values, language_id: parseInt(language_id) });
                  }}
                />
              </div>
              <div className="cv-settings-input-container">
                {!view ? (
                  <div className="cv-settings-button">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setView(1)}
                      size="large"
                    >
                      {"IZMJENI"}
                    </Button>
                  </div>
                ) : (
                  <Fragment>
                    <div className="cv-settings-button">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={updateInfo}
                        size="large"
                      >
                        {"SAČUVAJ"}
                      </Button>
                    </div>
                    <div className="cv-settings-button">
                      <Button
                        className="cv-settings-button"
                        variant="contained"
                        grayscale={'true'}
                        onClick={() => {
                          resetDefaults();
                          setView(0)
                        }}
                        size="large"
                      >
                        {"OTKAŽI"}
                      </Button>
                    </div>
                  </Fragment>
                )}
              </div>
            </SimpleCard>
            <SimpleCard
              withContent
              className="cv-dashboard-settings-content-side"
            >
              <DataDisplay
                label={"Datum registracije"}
                value={moment(user.created_at).format("DD.MM.YYYY.")}
              />
              <DataDisplay
                label={"Izmjeni lozinku"}
                value={"**********"}
                action={
                  <IconButton onClick={() => setPasswordModal(true)}>
                    <Lock />
                  </IconButton>
                }
              />
              {/*<DataDisplay*/}
              {/*  label={"Notifikacije"}*/}
              {/*  value={user.turn_notification ? "Uključene" : "Isključene"}*/}
              {/*  action={*/}
              {/*    <IconButton onClick={() => setNotificationsModal(true)}>*/}
              {/*      <SettingsIcon />*/}
              {/*    </IconButton>*/}
              {/*  }*/}
              {/*/>*/}
            </SimpleCard>
          </div>
        </div>
        <ChangePasswordModal open={passwordModal} onClose={() => setPasswordModal(false)} />
        {/*<NotificationsModal*/}
        {/*  open={notificationsModal}*/}
        {/*  onClose={() => setNotificationsModal(false)}*/}
        {/*  user={user}*/}
        {/*  values={values}*/}
        {/*  setValues={setValues}*/}
        {/*  notificationsData={notificationsData}*/}
        {/*  setNotificationsData={setNotificationsData}*/}
        {/*/>*/}
      </Fragment>
    </main>
  );
};


export default Settings;
