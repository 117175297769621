import * as types from './actionTypes';
import * as userApi from '../../api/user';

export function updateOptionalFieldsDataRequest() {
  return { type: types.UPDATE_OPTIONAL_FIELDS_REQUEST };
}

export function updateOptionalFieldsSuccess(data) {
  return { type: types.UPDATE_OPTIONAL_FIELDS_SUCCESS, data };
}

export function updateOptionalFieldsFailure(error) {
  return { type: types.UPDATE_OPTIONAL_FIELDS_FAILURE, error };
}

export function updateLicenceDataRequest() {
  return { type: types.UPDATE_LICENCE_REQUEST };
}

export function updateLicenceSuccess(data) {
  return { type: types.UPDATE_LICENCE_SUCCESS, data };
}

export function updateLicenceFailure(error) {
  return { type: types.UPDATE_LICENCE_FAILURE, error };
}

export function deleteComputerSkillDataRequest() {
  return { type: types.DELETE_COMPUTER_SKILL_REQUEST };
}

export function deleteComputerSkillSuccess(data) {
  return { type: types.DELETE_COMPUTER_SKILL_SUCCESS, data };
}

export function deleteComputerSkillFailure(error) {
  return { type: types.DELETE_COMPUTER_SKILL_FAILURE, error };
}

export function createComputerSkillDataRequest() {
  return { type: types.CREATE_COMPUTER_SKILL_REQUEST };
}

export function createComputerSkillSuccess(data) {
  return { type: types.CREATE_COMPUTER_SKILL_SUCCESS, data };
}

export function createComputerSkillFailure(error) {
  return { type: types.CREATE_COMPUTER_SKILL_FAILURE, error };
}

export function updateComputerSkillDataRequest() {
  return { type: types.UPDATE_COMPUTER_SKILL_REQUEST };
}

export function updateComputerSkillSuccess(data) {
  return { type: types.UPDATE_COMPUTER_SKILL_SUCCESS, data };
}

export function updateComputerSkillFailure(error) {
  return { type: types.UPDATE_COMPUTER_SKILL_FAILURE, error };
}

export function deleteUserExperienceDataRequest() {
  return { type: types.DELETE_USER_EXPERIENCE_REQUEST };
}

export function deleteUserExperienceSuccess(data) {
  return { type: types.DELETE_USER_EXPERIENCE_SUCCESS, data };
}

export function deleteUserExperienceFailure(error) {
  return { type: types.DELETE_USER_EXPERIENCE_FAILURE, error };
}

export function createUserExperienceDataRequest() {
  return { type: types.CREATE_USER_EXPERIENCE_REQUEST };
}

export function createUserExperienceSuccess(data) {
  return { type: types.CREATE_USER_EXPERIENCE_SUCCESS, data };
}

export function createUserExperienceFailure(error) {
  return { type: types.CREATE_USER_EXPERIENCE_FAILURE, error };
}

export function updateUserExperienceDataRequest() {
  return { type: types.UPDATE_USER_EXPERIENCE_REQUEST };
}

export function updateUserExperienceSuccess(data) {
  return { type: types.UPDATE_USER_EXPERIENCE_SUCCESS, data };
}

export function updateUserExperienceFailure(error) {
  return { type: types.UPDATE_USER_EXPERIENCE_FAILURE, error };
}

export function updateUserEducationDataRequest() {
  return { type: types.UPDATE_USER_EDUCATION_REQUEST };
}

export function updateUserEducationSuccess(data) {
  return { type: types.UPDATE_USER_EDUCATION_SUCCESS, data };
}

export function updateUserEducationFailure(error) {
  return { type: types.UPDATE_USER_EDUCATION_FAILURE, error };
}

export function createUserEducationDataRequest() {
  return { type: types.CREATE_USER_EDUCATION_REQUEST };
}

export function createUserEducationSuccess(data) {
  return { type: types.CREATE_USER_EDUCATION_SUCCESS, data };
}

export function createUserEducationFailure(error) {
  return { type: types.CREATE_USER_EDUCATION_FAILURE, error };
}

export function deleteUserEducationDataRequest() {
  return { type: types.DELETE_USER_EDUCATION_REQUEST };
}

export function deleteUserEducationSuccess(data) {
  return { type: types.DELETE_USER_EDUCATION_SUCCESS, data };
}

export function deleteUserEducationFailure(error) {
  return { type: types.DELETE_USER_EDUCATION_FAILURE, error };
}



export function loadUserRequest() {
  return { type: types.LOAD_USER_REQUEST };
}

export function loadUserSuccess(user) {
  return { type: types.LOAD_USER_SUCCESS, user };
}

export function loadUserFailure(error) {
  return { type: types.LOAD_USER_FAILURE, error };
}

export function loadNotificationsRequest() {
  return { type: types.LOAD_NOTIFICATIONS_REQUEST };
}

export function loadNotificationsSuccess(notifications) {
  return { type: types.LOAD_NOTIFICATIONS_SUCCESS, notifications };
}

export function loadNotificationsFailure(error) {
  return { type: types.LOAD_NOTIFICATIONS_FAILURE, error };
}

export function viewNotificationRequest() {
  return { type: types.VIEW_NOTIFICATION_REQUEST };
}
export function viewNotificationSuccess(id) {
  return { type: types.VIEW_NOTIFICATION_SUCCESS, id };
}

export function viewNotificationFailure(error) {
  return { type: types.VIEW_NOTIFICATION_FAILURE, error };
}

export function loadSingleMessageRequest() {
  return { type: types.LOAD_SINGLE_MESSAGE_REQUEST };
}

export function loadSingleMessageSuccess(message) {
  return { type: types.LOAD_SINGLE_MESSAGE_SUCCESS, message };
}

export function loadSingleMessageFailure(error) {
  return { type: types.LOAD_SINGLE_MESSAGE_FAILURE, error };
}

export function viewSingleMessageRequest() {
  return { type: types.VIEW_SINGLE_MESSAGE_REQUEST };
}

export function viewSingleMessageSuccess(id) {
  return { type: types.VIEW_SINGLE_MESSAGE_SUCCESS, id };
}

export function viewSingleMessageFailure(error) {
  return { type: types.VIEW_SINGLE_MESSAGE_FAILURE, error };
}

export function sendSingleMessageRequest() {
  return { type: types.SEND_SINGLE_MESSAGE_REQUEST };
}

export function sendSingleMessageSuccess(data) {
  return { type: types.SEND_SINGLE_MESSAGE_SUCCESS, data };
}

export function sendSingleMessageFailure(error) {
  return { type: types.SEND_SINGLE_MESSAGE_FAILURE, error };
}

export function loadAdminDashboardDataRequest() {
  return { type: types.LOAD_DASHBOARD_DATA_REQUEST };
}

export function loadAdminDashboardDataSuccess(data) {
  return { type: types.LOAD_DASHBOARD_DATA_SUCCESS, data };
}

export function loadAdminDashboardDataFailure(error) {
  return { type: types.LOAD_DASHBOARD_DATA_FAILURE, error };
}

export function loadSingleUserDataRequest() {
  return { type: types.LOAD_SINGLE_USER_DATA_REQUEST };
}

export function loadSingleUserDataSuccess(data) {
  return { type: types.LOAD_SINGLE_USER_DATA_SUCCESS, data };
}

export function loadSingleUserDataFailure(error) {
  return { type: types.LOAD_SINGLE_USER_DATA_FAILURE, error };
}

export function loadAllUsersDataRequest() {
  return { type: types.LOAD_ALL_USERS_DATA_REQUEST };
}

export function loadAllUsersDataSuccess(data) {
  return { type: types.LOAD_ALL_USERS_DATA_SUCCESS, data };
}

export function loadAllUsersDataFailure(error) {
  return { type: types.LOAD_ALL_USERS_DATA_FAILURE, error };
}

export function loadMessagesRequest() {
  return { type: types.LOAD_MESSAGES_REQUEST };
}

export function loadMessagesSuccess(data) {
  return { type: types.LOAD_MESSAGES_SUCCESS, data };
}

export function loadMessagesFailure(error) {
  return { type: types.LOAD_MESSAGES_FAILURE, error };
}

export function loadConversationRequest() {
  return { type: types.LOAD_CONVERSATION_REQUEST };
}

export function loadConversationSuccess(data) {
  return { type: types.LOAD_CONVERSATION_SUCCESS, data };
}

export function loadConversationFailure(error) {
  return { type: types.LOAD_CONVERSATION_FAILURE, error };
}

export function loadAllAddingsRequest() {
  return { type: types.LOAD_ALL_ADDINGS_REQUEST };
}

export function loadAllAddingsSuccess(data) {
  return { type: types.LOAD_ALL_ADDINGS_SUCCESS, data };
}

export function loadAllAddingsFailure(error) {
  return { type: types.LOAD_ALL_ADDINGS_FAILURE, error };
}

export function sendGroupMessageRequest() {
  return { type: types.SEND_GROUP_MESSAGE_REQUEST };
}

export function sendGroupMessageSuccess(message) {
  return { type: types.SEND_GROUP_MESSAGE_SUCCESS, message };
}

export function sendGroupMessageFailure(error) {
  return { type: types.SEND_GROUP_MESSAGE_FAILURE, error };
}

export function archiveMultipleUsersRequest() {
  return { type: types.ARCHIVE_MULTIPLE_USERS_REQUEST };
}

export function archiveMultipleUsersSuccess(response) {
  return { type: types.ARCHIVE_MULTIPLE_USERS_SUCCESS, response };
}

export function archiveMultipleUsersFailure(error) {
  return { type: types.ARCHIVE_MULTIPLE_USERS_FAILURE, error };
}

export function deactivateUserRequest() {
  return { type: types.DEACTIVATE_USER_REQUEST };
}

export function deactivateUserSuccess(data) {
  return { type: types.DEACTIVATE_USER_SUCCESS, data };
}

export function deactivateUserFailure(error) {
  return { type: types.DEACTIVATE_USER_FAILURE, error };
}

export function loadSearchedCompaniesRequest() {
  return { type: types.LOAD_SEARCHED_COMPANIES_REQUEST };
}

export function loadSearchedCompaniesSuccess(data) {
  return { type: types.LOAD_SEARCHED_COMPANIES_SUCCESS, data };
}

export function loadSearchedCompaniesFailure(error) {
  return { type: types.LOAD_SEARCHED_COMPANIES_FAILURE, error };
}

export function updateUserRequest() {
  return { type: types.UPDATE_USER_REQUEST };
}

export function updateUserSuccess(data) {
  return { type: types.UPDATE_USER_SUCCESS, data };
}

export function updateUserFailure(error) {
  return { type: types.UPDATE_USER_FAILURE, error };
}

export function updateProfileImageRequest() {
  return { type: types.UPDATE_PROFILE_IMAGE_REQUEST };
}

export function updateProfileImageSuccess(data) {
  return { type: types.UPDATE_PROFILE_IMAGE_SUCCESS, data };
}

export function updateProfileImageFailure(error) {
  return { type: types.UPDATE_PROFILE_IMAGE_FAILURE, error };
}

export function updateUserProfileImageRequest() {
  return { type: types.UPDATE_USER_PROFILE_IMAGE_REQUEST };
}

export function updateUserProfileImageSuccess(data) {
  return { type: types.UPDATE_USER_PROFILE_IMAGE_SUCCESS, data };
}

export function updateUserProfileImageFailure(error) {
  return { type: types.UPDATE_USER_PROFILE_IMAGE_FAILURE, error };
}

export function updateUserDataRequest() {
  return { type: types.UPDATE_USER_DATA_REQUEST };
}

export function updateUserDataSuccess(data) {
  return { type: types.UPDATE_USER_DATA_SUCCESS, data };
}

export function updateUserDataFailure(error) {
  return { type: types.UPDATE_USER_DATA_FAILURE, error };
}

export function loadUser() {
  return function (dispatch) {
    dispatch(loadUserRequest());
    return userApi
      .getUserData()
      .then(res => {
        if (res.success) {
          dispatch(loadUserSuccess(res.user));
        }
      })
      .catch(error => {
        dispatch(loadUserFailure(error));
      });
  };
}

export function getNotifications(data) {
  return function (dispatch) {
    dispatch(loadNotificationsRequest());
    return userApi
      .getNotifications(data)
      .then(res => {
        if (res.success) {
          dispatch(loadNotificationsSuccess(res));
        }
      })
      .catch(error => {
        dispatch(loadNotificationsFailure(error));
      });
  };
}

export function viewNotification(data) {
  return function (dispatch) {
    dispatch(viewNotificationRequest());
    return userApi
      .viewNotification(data)
      .then(res => {
        if (res.success) {
          dispatch(viewNotificationSuccess(data.id));
        }
      })
      .catch(error => {
        dispatch(viewNotificationFailure(error));
      });
  };
}

export function getMessage(data) {
  return function (dispatch) {
    dispatch(loadSingleMessageRequest());
    return userApi
      .getSingleMessage(data)
      .then(res => {
        if (res.success) {
          dispatch(loadSingleMessageSuccess(res.message));
        }
      })
      .catch(error => {
        dispatch(loadSingleMessageFailure(error));
      });
  };
}

export function viewSingleMessage(data) {
  return function (dispatch) {
    dispatch(viewSingleMessageRequest());
    return userApi
      .viewMessage(data)
      .then(res => {
        if (res.success) {
          dispatch(viewSingleMessageSuccess(data.id));
        }
      })
      .catch(error => {
        dispatch(viewSingleMessageFailure(error));
      });
  };
}

export function sendMessage(data) {
  return function (dispatch) {
    dispatch(sendSingleMessageRequest());
    return userApi
      .sendMessage(data)
      .then(res => {
        if (res.success) {
          dispatch(sendSingleMessageSuccess(res));
        }
      })
      .catch(error => {
        dispatch(sendSingleMessageFailure(error));
      });
  };
}

export function sendMultipleMessage(data) {
  return function (dispatch) {
    dispatch(sendGroupMessageRequest());
    return userApi
      .sendGroupMessage(data)
      .then(res => {
        if (res.success) {
          dispatch(sendGroupMessageSuccess(res));
        }
      })
      .catch(error => {
        dispatch(sendGroupMessageFailure(error));
      });
  };
}

export function getAdminDashboardData(data) {
  return function (dispatch) {
    dispatch(loadAdminDashboardDataRequest());
    return userApi
      .getAdminDashboard(data)
      .then(res => {
        if (res.data) {
          dispatch(loadAdminDashboardDataSuccess(res.data));
        }
      })
      .catch(error => {
        dispatch(loadAdminDashboardDataFailure(error));
      });
  };
}

export function getSingleUserData(data) {
  return function (dispatch) {
    dispatch(loadSingleUserDataRequest());
    return userApi
      .getSingleUser(data)
      .then(res => {
        if (res.success) {
          dispatch(loadSingleUserDataSuccess(res.user));
        }
      })
      .catch(error => {
        dispatch(loadSingleUserDataFailure(error));
      });
  };
}

export function getAllUsersData(data) {
  return function (dispatch) {
    dispatch(loadAllUsersDataRequest());
    return userApi
      .getAllUsers(data)
      .then(res => {
        if (res.success) {
          dispatch(loadAllUsersDataSuccess(res));
        }
      })
      .catch(error => {
        dispatch(loadAllUsersDataFailure(error));
      });
  };
}

export function getMessages(data) {
  return function (dispatch) {
    dispatch(loadMessagesRequest());
    return userApi
      .getMessages(data)
      .then(res => {
        if (res.success) {
          dispatch(loadMessagesSuccess(res));
        }
      })
      .catch(error => {
        dispatch(loadMessagesFailure(error));
      });
  };
}

export function getConversation(data) {
  return function (dispatch) {
    dispatch(loadConversationRequest());
    return userApi
      .getConversation(data)
      .then(res => {
        if (res.success) {
          dispatch(loadConversationSuccess(res));
        }
      })
      .catch(error => {
        dispatch(loadConversationFailure(error));
      });
  };
}

export function getAllAddings() {
  return function (dispatch) {
    dispatch(loadAllAddingsRequest());
    return userApi
      .getAllAddings()
      .then(res => {
        if (res.success) {
          dispatch(loadAllAddingsSuccess(res));
        }
      })
      .catch(error => {
        dispatch(loadAllAddingsFailure(error));
      });
  };
}

export function archiveMultipleUsers(data) {
  return function (dispatch) {
    dispatch(archiveMultipleUsersRequest());
    return userApi
      .archiveMultipleUsers(data)
      .then(res => {
        if (res.success) {
          dispatch(archiveMultipleUsersSuccess(data));
        }
      })
      .catch(error => {
        dispatch(archiveMultipleUsersFailure(error));
      });
  };
}

export function deactivateUser(data) {
  return function (dispatch) {
    dispatch(deactivateUserRequest());
    return userApi
      .deactivateUser(data)
      .then(res => {
        if (res.success) {
          dispatch(deactivateUserSuccess(res));
        }
      })
      .catch(error => {
        dispatch(deactivateUserFailure(error));
      });
  };
}

export function searchedCompanies(data) {
  return function (dispatch) {
    dispatch(loadSearchedCompaniesRequest());
    return userApi
      .getCompanies({ ...data, limit: 25, offset: 0 })
      .then(res => {
        if (res.success) {
          dispatch(loadSearchedCompaniesSuccess(res.companies));
        }
      })
      .catch(error => {
        dispatch(loadSearchedCompaniesFailure(error));
      });
  };
}

export function updateUser(data) {
  return function (dispatch) {
    dispatch(updateUserRequest());
    return userApi
      .updateUser(data)
      .then(res => {
        if (res.success) {
          dispatch(updateUserSuccess(res));
        }
      })
      .catch(error => {
        dispatch(updateUserFailure(error));
      });
  };
}

export function updateProfileImage(data) {
  return function (dispatch) {
    dispatch(updateProfileImageRequest());
    return userApi
      .updateProfileImageAdmin(data)
      .then(res => {
        if (res.success) {
          dispatch(updateProfileImageSuccess(res.user));
        }
      })
      .catch(error => {
        dispatch(updateProfileImageFailure(error));
      });
  };
}

export function updateUserProfileImage(data) {
  return function (dispatch) {
    dispatch(updateUserProfileImageRequest());
    return userApi
      .updateProfileImage(data)
      .then(res => {
        if (res.success) {
          dispatch(updateUserProfileImageSuccess(res.user));
        }
      })
      .catch(error => {
        dispatch(updateUserProfileImageFailure(error));
      });
  };
}

export function updateUserData(data) {
  return function (dispatch) {
    dispatch(updateUserDataRequest());
    return userApi
      .updateUserData(data)
      .then(res => {
        if (res.success) {
          dispatch(updateUserDataSuccess(data));
        }
      })
      .catch(error => {
        dispatch(updateUserDataFailure(error));
      });
  };
}


export function createUserEducation(data) {
  return function (dispatch) {
    dispatch(createUserEducationDataRequest());
    return userApi
      .createEducation(data)
      .then(res => {
        if (res.success) {
          dispatch(createUserEducationSuccess(data));
        }
      })
      .catch(error => {
        dispatch(createUserEducationFailure(error));
      });
  };
}

export function deleteUserEducation(data) {
  return function (dispatch) {
    dispatch(deleteUserEducationDataRequest());
    return userApi
      .deleteEducation(data)
      .then(res => {
        if (res.success) {
          dispatch(deleteUserEducationSuccess(res.data));
        }
      })
      .catch(error => {
        dispatch(deleteUserEducationFailure(error));
      });
  };
}

export function updateUserEducation(id, data) {
  return function (dispatch) {
    dispatch(updateUserEducationDataRequest());
    return userApi
      .updateEducation(id, data)
      .then(res => {
        if (res.success) {
          dispatch(updateUserEducationSuccess(res.data));
        }
      })
      .catch(error => {
        dispatch(updateUserEducationFailure(error));
      });
  };
}



export function createUserExperience(data) {
  return function (dispatch) {
    dispatch(createUserExperienceDataRequest());
    return userApi
      .createExperience(data)
      .then(res => {
        if (res.success) {
          dispatch(createUserExperienceSuccess(data));
        }
      })
      .catch(error => {
        dispatch(createUserExperienceFailure(error));
      });
  };
}

export function deleteUserExperience(data, user_id) {
  return function (dispatch) {
    dispatch(deleteUserExperienceDataRequest());
    return userApi
      .deleteExperience(data, user_id)
      .then(res => {
        if (res.success) {
          dispatch(deleteUserExperienceSuccess(res.data));
        }
      })
      .catch(error => {
        dispatch(deleteUserExperienceFailure(error));
      });
  };
}

export function updateUserExperience(id, data) {
  return function (dispatch) {
    dispatch(updateUserExperienceDataRequest());
    return userApi
      .updateExperience(id, data)
      .then(res => {
        if (res.success) {
          dispatch(updateUserExperienceSuccess(res.data));
        }
      })
      .catch(error => {
        dispatch(updateUserExperienceFailure(error));
      });
  };
}

export function createComputerSkill(data) {
  return function (dispatch) {
    dispatch(createComputerSkillDataRequest());
    return userApi
      .createSkill(data)
      .then(res => {
        if (res.success) {
          dispatch(createComputerSkillSuccess(data));
        }
      })
      .catch(error => {
        dispatch(createComputerSkillFailure(error));
      });
  };
}

export function deleteComputerSkill(data, user_id) {
  return function (dispatch) {
    dispatch(deleteComputerSkillDataRequest());
    return userApi
      .deleteSkill(data, user_id)
      .then(res => {
        if (res.success) {
          dispatch(deleteComputerSkillSuccess(res.data));
        }
      })
      .catch(error => {
        dispatch(deleteComputerSkillFailure(error));
      });
  };
}

export function updateComputerSkill(id, data) {
  return function (dispatch) {
    dispatch(updateComputerSkillDataRequest());
    return userApi
      .updateSkill(id, data)
      .then(res => {
        if (res.success) {
          dispatch(updateComputerSkillSuccess(res.data));
        }
      })
      .catch(error => {
        dispatch(updateComputerSkillFailure(error));
      });
  };
}


export function updateLicence(body) {
  return function (dispatch) {
    dispatch(updateLicenceDataRequest());
    return userApi
      .updateLicence(body)
      .then(res => {
        if (res.success) {
          dispatch(updateLicenceSuccess(res.data));
        }
      })
      .catch(error => {
        dispatch(updateLicenceFailure(error));
      });
  };
}


export function updateOptionalFields(body) {
  return function (dispatch) {
    dispatch(updateOptionalFieldsDataRequest());
    return userApi
      .updateOptionalFields(body)
      .then(res => {
        if (res.success) {
          dispatch(updateOptionalFieldsSuccess(res.data));
        }
      })
      .catch(error => {
        dispatch(updateOptionalFieldsFailure(error));
      });
  };
}
