import { IconButton } from '@material-ui/core';
import { Close, Delete, Edit, GetApp, Replay, Save } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { AdminAPI, CandidateAPI, CompanyAPI, GlobalAPI } from '../../api/v2';
import Button from '../../components/button/index';
import DatePicker from '../../components/datepicker/index';
import Input from '../../components/input';
import InputCard from '../../components/inputcard';
import RichText from '../../components/RichText';
import Select from '../../components/select';
import { Title } from '../../components/Title';
import localizedStrings from '../../utils/localizedStrings';
import Modal from '../../components/modal/index';
import { toast } from 'react-toastify';
import moment from 'moment';
import { apiBaseUrl } from '../../api/config';

const PreviewCandidate = () => {
  const [candidate, setCandidate] = useState(null);

  const { id } = useParams();

  const [loaded, setLoaded] = useState(false);

  const history = useHistory();

  const goTo = x => {
    history.push(x);
  };

  const back = () => {
    history.goBack();
  };

  const edit = () => {
    history.push(`/candidate/${id}/edit`);
  };

  const getCandidate = async () => {
    const res = await CandidateAPI.get(id);
    setCandidate(res);
    setLoaded(true);
  };

  useEffect(() => {
    getCandidate();
  }, []);

  return (
    <Fragment>
      <main id="mainContent" className="xl-resize">
        <Title title="Pregled kandidata" />
        <header>
          <div className="title-box">
            <h1>Pregled kandidata</h1>
            <p>Informacije o kandidatu</p>
          </div>
        </header>

        <div className="add-ad-container">
          <h2 className="title">Pregled kandidata</h2>
          {loaded ? (
            <Fragment>
              <div className="candidate-main">
                <div className="candidate-profile-row">
                  <div className="candidate-image-holder">
                    <img
                      src={
                        candidate.profile_image ||
                        '/images/mockup/profile-img-placeholder.png'
                      }
                      alt={candidate.full_name}
                    />
                  </div>
                  <div className="candidate-profile-info">
                    <h1>{candidate.full_name}</h1>
                    {candidate.birth_year ? (
                      <p>{moment(candidate.birth_year).format('DD.MM.YYYY')}</p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="candidate-row">
                  <div className="add-ad-half">
                    <div className="add-ad-full-info">
                      <div className="add-ad-row-info">
                        <span>Pol</span> {candidate.gender?.name || '/'}
                      </div>
                      <div className="add-ad-row-info">
                        <span>Kontakt email</span> {candidate.email || '/'}
                      </div>
                      <div className="add-ad-row-info">
                        <span>Obrazovanje</span>
                        {candidate.education_level?.name || '/'}
                      </div>
                      <div className="add-ad-row-info">
                        <span>Država</span>
                        {candidate.country?.name || '/'}
                      </div>
                      <div className="add-ad-row-info">
                        <span>Grad</span>
                        {candidate.city?.name || '/'}
                      </div>
                      <div className="add-ad-row-info">
                        <span>Ulica i broj prebivališta</span>
                        {candidate.address || '/'}
                      </div>
                      <div className="add-ad-row-info">
                        <span>Kontakt telefon</span>
                        {candidate.phone || '/'}
                      </div>
                      <div className="add-ad-row-info">
                        <span>Rad na računaru</span>
                        {candidate.computer_skills
                          ?.map(x => x.computer_skill_name.name)
                          .filter(x => ![null, undefined, ''].includes(x))
                          .join(', ') || '/'}
                      </div>
                      <div className="add-ad-row-info">
                        <span>Strani jezici</span>
                        {candidate.languages
                          ?.map(x => x.languages_name)
                          .filter(x => ![null, undefined, ''].includes(x))
                          .join(', ') || '/'}
                      </div>
                      <div className="add-ad-row-info">
                        <span>Radno iskustvo</span>
                        {[
                          candidate.work_experiences
                            ?.map(x =>
                              moment(x.end_date).diff(x.start_date, 'days')
                            )
                            .filter(x => ![NaN, undefined, null].includes(x))
                            .reduce((a, b) => a + b, 0) / 365
                        ]
                          .map(x =>
                            x
                              ? x < 1
                                ? 'Manje od godinu dana'
                                : `${Math.round(x)} godina/e`
                              : 'Bez radnog iskustva'
                          )
                          .join()}
                      </div>
                    </div>
                  </div>
                  <div className="add-ad-half">
                    <div className="add-ad-row-info">
                      <span>CV Dokument</span>
                      {candidate.documents.map((x, y) => (
                        <a
                          href={`${apiBaseUrl}/${x.document_link}`}
                          download
                          className="candidate-link"
                          key={y}
                        >
                          <GetApp />
                          {x.document_name}
                        </a>
                      ))}
                      {!candidate.documents.length ? '/' : ''}
                    </div>
                    <div className="force-space-b">
                      <span>CV Video biografija</span>
                      <div className="candidate-video">
                        {candidate.cv_video ? (
                          <video
                            src={candidate.cv_video.video}
                            controls
                          ></video>
                        ) : (
                          <img src="/images/mockup/video-placeholder.png" />
                        )}
                      </div>
                    </div>
                    <div className="add-ad-row-info">
                      Ukratko o meni
                      <span>{candidate.aditional_info}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-ad-actions">
                <Button className="button-inline" onClick={edit}>
                  <Edit className="hide-on-mobile-sm" />
                  Izmeni kandidata
                </Button>
                <Button grayscale={'true'} className="button-inline" onClick={back}>
                  <Replay className="hide-on-mobile-sm" />
                  Nazad
                </Button>
              </div>
            </Fragment>
          ) : (
            <div className="edit-ad-loading">
              <h1>Kandidat se učitava</h1>
              <p>Molimo sačekajte</p>
            </div>
          )}
        </div>
      </main>
    </Fragment>
  );
};

export default PreviewCandidate;
