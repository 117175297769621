import { useState, useEffect, Fragment } from 'react';
import { CandidateAPI } from "../../../../api/v2";
import Modal from "../../../../components/custom/modal";
import Button from "../../../../components/button/index";
import DataDisplay from "../../../../components/custom/data-display";
import { Delete } from "@mui/icons-material";
import { IconButton } from "@material-ui/core";
import Select from "../../../../components/select";

const ComputerSkillsModal = ({ skills, open, onClose, addings, userId, ...parent }) => {
  const [computerSkills, setComputerSkills] = useState(skills);
  const [state, setState] = useState({
    id: 0,
    computer_skill_name_id: "",
    computer_skill_knowledge_level_id: "",
  });

  useEffect(() => {
    setComputerSkills(skills);
  }, [skills]);

  const [computerSkillsOptions, setComputerSkillsOptions] = useState([]);
  const [skillLevels, setSkillLevels] = useState([]);

  const clearFields = () => {
    setState({
      id: 0,
      computer_skill_name_id: "",
      computer_skill_knowledge_level_id: "",
    });
  };

  useEffect(() => {
    setSkillLevels(
      addings?.computer_skills_levels.map((x) => ({
        value: x.id,
        label: x.name,
      }))
    );
    setComputerSkillsOptions(
      addings?.computer_skills_names.map((x) => ({ value: x.id, label: x.name }))
    );
  }, []);

  const [deleteOpen, setDeleteOpen] = useState(null);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setDeleteOpen(id);
  };

  const loadSkill = (x) => {
    setState(x);
  };

  const addSkill = async () => {
    try {
      const { id, ...other } = state;
      if (id === 0) {
        const res = await CandidateAPI.computerSkillStore(other, userId);
        parent.setState({
          ...parent.state,
          computer_skills: [...parent.state.computer_skills, res?.data?.data],
        });
        clearFields();
      } else {
        const res = await CandidateAPI.computerSkillUpdate(id, other, userId);
        parent.setState({
          ...parent.state,
          computer_skills: parent.state.computer_skills.map((x) =>
            x.id !== id ? x : res?.data?.data
          ),
        });
        clearFields();
      }
    } catch (e) {}
  };

  const deleteSkill = async () => {
    await CandidateAPI.computerSkillDelete(deleteOpen, userId);
    parent.setState({
      ...parent.state,
      computer_skills: parent.state.computer_skills.filter(
        (x) => x.id !== deleteOpen
      ),
    });
    if (deleteOpen === state.id) {
      clearFields();
    }
    setDeleteOpen(null);
  };

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title="RAD NA RAČUNARU"
        size="large"
        actions={[
          <Button
            grayscale={'true'}
            variant="contained"
            size="large"
            fullWidth
            onClick={clearFields}
            key={0}
          >
            {"OČISTI POLJA"}
          </Button>,
          <Button
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            onClick={addSkill}
            key={1}
          >
            {state.id === 0 ? "DODAJ NOVU VJESTINU" : "SAČUVAJ"}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-profile-edit-modal-main">
          <div className="cv-dashboard-profile-edit-modal-side">
            {computerSkills?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className={`cv-dashboard-profile-edit-single ${
                  x.id === state.id ? "cv-dashboard-profile-edit-active" : ""
                }`}
                label={`Vještina ${y + 1}`}
                value={x?.computer_skill_name?.name}
                onClick={() => loadSkill(x)}
                action={
                  <IconButton onClick={(e) => handleDelete(e, x.id)}>
                    <Delete />
                  </IconButton>
                }
              />
            ))}
          </div>
          <div className="cv-dashboard-profile-edit-modal-side">
            <div className="cv-dashboard-profile-edit-modal-grid">
              <Select
                className="cv-dashboard-profile-col-double"
                label={"Vještina"}
                value={state.computer_skill_name_id}
                onChange={(computer_skill_name_id) =>
                  setState({ ...state, computer_skill_name_id })
                }
                color="secondary"
                variant="outlined"
                options={computerSkillsOptions}
                required
              />
              <Select
                className="cv-dashboard-profile-col-double"
                label={"Nivo znanja"}
                value={state.computer_skill_knowledge_level_id}
                onChange={(computer_skill_knowledge_level_id) =>
                  setState({ ...state, computer_skill_knowledge_level_id })
                }
                color="secondary"
                variant="outlined"
                options={skillLevels}
                required
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={typeof deleteOpen === 'number'}
        onClose={() => setDeleteOpen(null)}
        title={"OBRIŠI"}
        size="medium"
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={deleteSkill}
            key={2}
          >
            {"OBRIŠI"}
          </Button>,
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setDeleteOpen(null)}
            key={3}
          >
            {"OTKAŽI"}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-cvvideo-modal-content">
          {"Da li ste sigurni da želite da obrišete vještinu?"}
        </div>
      </Modal>
    </Fragment>
  );
};

export default ComputerSkillsModal;
