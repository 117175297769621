import React, { Fragment, useState } from 'react';

import Modal from "../../../components/modal";
import Input from "../../../components/ui/input";
import Button from "../../../components/button/index";
import { toast } from "react-toastify";
import { AuthAPI } from "../../../api/v2";

const ChangePasswordModal = ({ open, onClose }) => {
  const [passwords, setPasswords] = useState({
    old_password: '',
    new_password: '',
    repeat_new_password: ''
  });

  const changePassword = async () => {
    if (Object.keys(passwords).filter(x => !x.trim()).length) {
      return toast.warn('Popunite sva polja');
    }
    if (
      passwords.new_password.trim() !== passwords.repeat_new_password.trim()
    ) {
      return toast.warn('Lozinke se ne podudaraju');
    }
    try {
      await AuthAPI.changePassword(passwords);
      toast.success('Lozinka je uspešno promenjena');
      onClose();
    } catch (e) {
      toast.error('Došlo je do greške');
    }
  };

  return (
    <Modal title="Izmena lozinke" open={open} onClose={onClose}>
      <Fragment>
        <Input
          className="force-space-b"
          label="Stara lozinka"
          value={passwords.old_password}
          onChange={old_password => setPasswords({ ...passwords, old_password })}
          type="password"
        />
        <Input
          className="force-space-b"
          label="Nova lozinka"
          value={passwords.new_password}
          onChange={new_password => setPasswords({ ...passwords, new_password })}
          type="password"
        />
        <Input
          className="force-space-b"
          label="Potvrdite novu lozinku"
          value={passwords.repeat_new_password}
          type="password"
          onChange={repeat_new_password =>
            setPasswords({ ...passwords, repeat_new_password })
          }
        />
        <Button onClick={changePassword}>Izmeni lozinku</Button>
      </Fragment>
    </Modal>
  )
};

export default ChangePasswordModal;
